import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Attribute } from "src/app/models/attribute.model";
import { Patch } from "src/app/models/patch.model";
import { Weapon } from "src/app/models/weapon.model";
import { WeaponProfile } from "src/app/models/weaponprofile.model";
import { WeaponProfileMode } from "src/app/models/weaponprofilemode.model";
import { AttributeService } from "src/app/services/attribute.service";
import { WeaponService } from "src/app/services/weapon.service";
import { WeaponProfileModeService } from "src/app/services/weaponprofilemode.service";

@Component({
  selector: "app-weaponprofilemode",
  templateUrl: "./weaponprofilemode.component.html",
  preserveWhitespaces: true,
})
export class WeaponProfileModeComponent {
  attributes: Attribute[] = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalTitle: string;
  modalBody: string;
  modalId: string;

  selectedAttribute: Attribute;

  attributeDisplay: string = "0";

  weapon: Weapon;
  weaponId: string = "";
  weaponName: string = "Weapon";

  profile: WeaponProfile;
  profileId: string = "";
  profileName: string = "Profile";

  mode: WeaponProfileMode;
  modeName: string = "Mode";

  alerts: Alert[] = [];

  hasLoadedWeapon: boolean = false;
  hasLoadedProfile: boolean = false;
  hasLoadedMode: boolean = false;
  hasLoadedAttributes: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private weaponService: WeaponService,
    private modeService: WeaponProfileModeService,
    private attributeService: AttributeService,
    private modalService: NgbModal
  ) {
    this.getWeapon();
    this.getMode();
  }

  getWeapon() {
    this.weaponService
      .get<Weapon>({
        id: this.activatedRoute.snapshot.paramMap.get("weaponId"),
      })
      .then(
        (result) => {
          this.weapon = result;
          this.weaponId = this.weapon.id;
          this.weaponName = this.weapon.name;
          this.hasLoadedWeapon = true;
        },
        (error) => {
          this.hasLoadedWeapon = false;
          this.addAlert({
            type: "danger",
            message: "Weapon could not be retrieved!",
          });
        }
      );
  }

  getMode() {
    this.modeService
      .getFull<WeaponProfileMode>({
        weaponProfileId:
          this.activatedRoute.snapshot.paramMap.get("weaponProfileId"),
        id: this.activatedRoute.snapshot.paramMap.get("weaponProfileModeId"),
      })
      .then(
        (result) => {
          this.mode = result;
          this.hasLoadedMode = true;
          this.profile = result.weaponProfile;
          this.profileId = this.profile.id;
          this.profileName = this.profile.name;
          this.hasLoadedProfile = true;
          this.attributes = result.attributes;
          this.hasLoadedAttributes = true;
          this.setName();
        },
        (error) => {
          this.hasLoadedMode = false;
          this.hasLoadedProfile = false;
          this.hasLoadedAttributes = false;
          this.addAlert({
            type: "danger",
            message: "Mode could not be retrieved!",
          });
        }
      );
  }

  refreshAttributes() {
    this.resetAttribute();
    this.attributes = [];
    this.modeService
      .getFull<WeaponProfileMode>({
        weaponProfileId: this.profile.id,
        id: this.mode.id,
      })
      .then(
        (result) => {
          this.attributes = result.attributes;
          this.hasLoadedAttributes = true;
        },
        (error) => {
          this.hasLoadedAttributes = false;
          this.addAlert({
            type: "danger",
            message: "Attributes could not be retrieved!",
          });
        }
      );
  }

  setAttribute(id: string) {
    this.resetAttribute();
    this.selectedAttribute = this.attributes.find((row) => {
      return row.id === id;
    });
    this.setDisplay();
  }

  setName() {
    this.modeName = this.mode.name;
  }

  setDisplay() {
    this.attributeDisplay = this.selectedAttribute.display;
  }

  setAttributeModal(id?: string, title?: string, body?: string) {
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  resetAttribute() {
    this.selectedAttribute = {
      id: "",
      name: "",
      description: "",
      shortName: "",
      display: "",
      roll: 0,
      sides: 0,
      multiply: 0,
      max: 0,
      min: 0,
      priority: 0,
      created: "",
      modified: "",
      isActive: true,
      useParent: false,
    };
  }

  patch(patch: Patch, field: string) {
    this.modeService
      .patch<Patch>([patch], {
        weaponProfileId: this.profile.id,
        id: this.mode.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: 'Field: "' + field + '" was updated successfully!',
          });
          this.setName();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: 'Field: "' + field + '" could not be updated!',
          });
        }
      );
  }

  editName() {
    this.patch({ op: "replace", path: "/name", value: this.mode.name }, "Name");
  }

  newAttribute() {
    this.selectedAttribute.display = this.attributeDisplay;
    var value = this.attributeDisplay;
    var max = 0;
    var min = 0;
    var roll = 0;
    var sides = 0;
    var multiply = 1;
    var useParent = false;
    switch (true) {
      case value.indexOf("D") !== -1: {
        var values = value.split("D");
        min = Number(values[0]);
        max = min * Number(values[1]);
        roll = min;
        sides = Number(values[1]);
        break;
      }
      case value.indexOf("Melee") !== -1 || value.indexOf("User") !== -1: {
        useParent = true;
        break;
      }
      case value.indexOf("+") !== -1: {
        var values = value.split("+");
        min = Number(values[0]);
        roll = 1;
        sides = 6;
        break;
      }
      case value.indexOf("x") !== -1: {
        var values = value.split("x");
        multiply = Number(values[1]);
        useParent = true;
        break;
      }
      default: {
        max = value ? Number(value) : max;
        break;
      }
    }
    this.selectedAttribute = {
      id: this.selectedAttribute.id,
      name: this.selectedAttribute.name,
      description: this.selectedAttribute.description,
      shortName: this.selectedAttribute.shortName,
      display: value,
      roll: roll,
      sides: sides,
      multiply: multiply,
      max: max,
      min: min,
      priority: this.selectedAttribute.priority,
      created: "",
      modified: "",
      isActive: true,
      useParent: useParent,
    };
  }

  updateAttributeForm(content: any, value: string) {
    this.setAttribute(value);
    this.setAttributeModal(value, "Update " + this.selectedAttribute.name, "");
    this.modalService.open(content, { centered: true });
  }

  updateAttribute() {
    this.newAttribute();
    this.attributeService
      .put<Attribute>(this.selectedAttribute, { id: this.selectedAttribute.id })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message:
              'Attribute: "' +
              this.selectedAttribute.name +
              '" was updated successfully!',
          });
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Attribute: "' +
              this.selectedAttribute.name +
              '" could not be updated!',
          });
        }
      );
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
