import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ErrorComponent } from "./error/error.component";

const routes: Routes = [
  {
    path: "403",
    component: ErrorComponent,
    data: {
      type: 403,
      title: "Forbidden",
      desc: "Access denied!",
    },
  },
  {
    path: "404",
    component: ErrorComponent,
    data: {
      type: 404,
      title: "Page Not Found",
      desc: "Page does not exist!",
    },
  },
  {
    path: "500",
    component: ErrorComponent,
    data: {
      type: 500,
      title: "Internal Error",
      desc: "An internal server error has occured!",
    },
  },
  { path: "", redirectTo: "404", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ErrorRoutingModule {}
