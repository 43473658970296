import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
})
export class ErrorComponent implements OnInit, OnDestroy {
  type: any;
  title: any;
  desc: any;
  private sub: Subscription;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get("type");

    this.sub = this.route.data.subscribe((param) => {
      if (param.type) {
        this.type = param.type;
      }
      if (param.title) {
        this.title = param.title;
      }
      if (param.desc) {
        this.desc = param.desc;
      }
    });

    switch (this.type) {
      case "403":
        if (!this.title) {
          this.title = "Forbidden";
        }
        if (!this.desc) {
          this.desc = "Oops!! You do not have access to this page.";
        }
        break;
      case "404":
        if (!this.title) {
          this.title = "Page Not Found";
        }
        if (!this.desc) {
          this.desc = "Oops!! The page you were looking for doesn't exist.";
        }
        break;
      case "500":
        if (!this.title) {
          this.title = "Internal server error";
        }
        if (!this.desc) {
          this.desc = "Oops!! There wan an error. Please try again later.";
        }
        break;
      default:
        // if (!this.type) {
        this.type = "Oops..";
        // }
        if (!this.title) {
          this.title = "Something went wrong";
        }
        if (!this.desc) {
          this.desc =
            "Looks like something went wrong.<br>" + "We're working on it";
        }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
