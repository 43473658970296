import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class FactionService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getData<T>(): Promise<T> {
    var url = this.baseUrl + "api/Factions/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  get<Faction>(ids): Promise<Faction> {
    var url = this.baseUrl + "api/Factions/" + ids.id + this.getCacheBust;
    return this.http.get<Faction>(url).toPromise();
  }

  getFull<Faction>(ids): Promise<Faction> {
    var url = this.baseUrl + "api/Factions/" + ids.id + "/Full" + this.getCacheBust;
    return this.http.get<Faction>(url).toPromise();
  }

  put<Faction>(item, ids): Observable<Faction> {
    var url = this.baseUrl + "api/Factions/" + ids.id;
    return this.http.put<Faction>(url, item);
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/Factions/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<Faction>(item, ids): Observable<Faction> {
    var url = this.baseUrl + "api/Factions/New/GameMode/" + ids.gameModeId;
    return this.http.post<Faction>(url, item);
  }

  delete<Faction>(ids): Observable<Faction> {
    var url = this.baseUrl + "api/Factions/" + ids.id;
    return this.http.delete<Faction>(url);
  }

  upload<Upload>(item): Observable<Upload> {
    var url = this.baseUrl + "api/Upload/";
    return this.http.post<Upload>(url, item);
  }
}
