import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Alert } from "src/app/models/alert.model";
import { Patch } from "src/app/models/patch.model";
import { WeaponGroup } from "src/app/models/weapongroup.model";
import { WeaponGroupService } from "src/app/services/weapongroup.service";

@Component({
  selector: "app-weapongroup",
  templateUrl: "./weapongroup.component.html",
  preserveWhitespaces: true,
})
export class WeaponGroupComponent {
  group: WeaponGroup;
  groupName: string = "Group";

  alerts: Alert[] = [];

  hasLoadedGroup: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private groupService: WeaponGroupService
  ) {
    this.getWeapon();
  }

  getWeapon() {
    this.groupService
      .get<WeaponGroup>({
        id: this.activatedRoute.snapshot.paramMap.get("weaponGroupId"),
      })
      .then(
        (result) => {
          this.group = result;
          this.hasLoadedGroup = true;
          this.setName();
        },
        (error) => {
          this.hasLoadedGroup = false;
          this.addAlert({
            type: "danger",
            message: "Group could not be retrieved!",
          });
        }
      );
  }

  setName() {
    this.groupName = this.group.name;
  }

  patch(patch: Patch, field: string) {
    this.groupService.patch<Patch>([patch], { id: this.group.id }).subscribe(
      (result) => {
        this.addAlert({
          type: "success",
          message: 'Field: "' + field + '" was updated successfully!',
        });
        this.setName();
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: 'Field: "' + field + '" could not be updated!',
        });
      }
    );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.group.name },
      "Name"
    );
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
