import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
// Ng-ApexCharts
import { NgApexchartsModule } from "ng-apexcharts";
// Ng2-charts
import { ChartsModule } from "ng2-charts";
import { FeatherIconModule } from "src/app/core/feather-icon/feather-icon.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { HomeRoutingModule } from "./home-routing.module";
import { SimulationsComponent } from "./simulations/simulations.component";

@NgModule({
  declarations: [DashboardComponent, SimulationsComponent],
  imports: [
    CommonModule,
    FormsModule,
    HomeRoutingModule,
    FeatherIconModule,
    NgbModule,
    NgxDatatableModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgApexchartsModule,
    ChartsModule,
  ],
  bootstrap: [],
})
export class HomeModule {}
