import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Match } from "src/app/models/match.model";
import { Simulation } from "src/app/models/simulation.model";
import { MatchService } from "src/app/services/match.service";
import { SimulationService } from "src/app/services/simulation.service";

@Component({
  selector: "app-resultlog",
  templateUrl: "./resultlog.component.html",
  preserveWhitespaces: true,
})
export class ResultLogComponent {
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  match: Match;
  matchName: string = "Match";

  simulation: Simulation;
  simulationId: string = "";
  simulationName: string = "Simulation";

  alerts: Alert[] = [];

  hasLoadedMatch: boolean;
  hasLoadedSimulation: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private matchService: MatchService,
    private simulationService: SimulationService
  ) {
    this.getSimulation();
    this.getMatch();
  }

  getSimulation() {
    this.simulationService
      .get<Simulation>({
        id: this.activatedRoute.snapshot.paramMap.get("simulationId"),
      })
      .then(
        (result) => {
          this.simulation = result;
          this.hasLoadedSimulation = true;
          this.setSimulationId();
          this.setSimulationName();
        },
        (error) => {
          this.hasLoadedSimulation = false;
          this.addAlert({
            type: "danger",
            message: "Simulation could not be retrieved!",
          });
        }
      );
  }

  getMatch() {
    this.matchService
      .getFull<Match>({
        id: this.activatedRoute.snapshot.paramMap.get("matchId"),
      })
      .then(
        (result) => {
          this.match = result;
          this.hasLoadedMatch = true;
          this.setMatchName();
        },
        (error) => {
          this.hasLoadedMatch = false;
          this.addAlert({
            type: "danger",
            message: "Match could not be retrieved!",
          });
        }
      );
  }

  setSimulationId() {
    this.simulationId = this.simulation.id;
  }

  setSimulationName() {
    this.simulationName = this.simulation.name;
  }

  setMatchName() {
    this.matchName = this.match.name;
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
