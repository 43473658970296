import { Component } from "@angular/core";
import { AuthService } from "src/app/core/auth/auth.service";

@Component({
  selector: "app-access",
  templateUrl: "./access.component.html",
  styleUrls: ["./access.component.scss"],
})
export class AccessComponent {
  constructor(private authService: AuthService) {}

  login() {
    this.authService.login("/");
  }

  register() {
    this.authService.register();
  }
}
