import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Branch } from "src/app/models/branch.model";
import { BranchFirstName } from "src/app/models/branchfirstname.model";
import { BranchLastName } from "src/app/models/branchlastname.model";
import { Faction } from "src/app/models/faction.model";
import { Patch } from "src/app/models/patch.model";
import { Upload } from "src/app/models/upload.model";
import { BranchService } from "src/app/services/branch.service";
import { BranchFirstNameService } from "src/app/services/branchfirstname.service";
import { BranchLastNameService } from "src/app/services/branchlastname.service";
import { FactionService } from "src/app/services/faction.service";

@Component({
  selector: "app-branch",
  templateUrl: "./branch.component.html",
  preserveWhitespaces: true,
})
export class BranchComponent {
  firstNames = [];
  lastNames = [];

  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalTitle: string;
  modalBody: string;
  modalId: string;

  faction: Faction;
  factionId: string = "";
  factionName: string = "Faction";

  branch: Branch;
  branchName: string = "Branch";
  branchImagePath: string;

  selectedFirstName: BranchFirstName;
  selectedLastName: BranchLastName;

  alerts: Alert[] = [];

  filePath: string;

  hasLoadedBranch: boolean = false;
  hasLoadedFirstNames: boolean = false;
  hasLoadedLastNames: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private factionService: FactionService,
    private branchService: BranchService,
    private branchFirstNameService: BranchFirstNameService,
    private branchLastNameService: BranchLastNameService,
    private modalService: NgbModal
  ) {
    this.getBranch();
  }

  getBranch() {
    this.branchService
      .getFull<Branch>({
        id: this.activatedRoute.snapshot.paramMap.get("branchId"),
      })
      .then(
        (result) => {
          this.branch = result;
          this.faction = this.branch.faction;
          this.factionId = this.faction.id;
          this.factionName = this.faction.name;
          this.hasLoadedBranch = true;
          this.firstNames = this.branch.branchFirstNames;
          this.hasLoadedFirstNames = true;
          this.lastNames = this.branch.branchLastNames;
          this.hasLoadedLastNames = true;
          this.setName();
          this.setPath();
        },
        (error) => {
          this.hasLoadedBranch = false;
          this.hasLoadedFirstNames = false;
          this.hasLoadedLastNames = false;
          this.addAlert({
            type: "danger",
            message: "Branch could not be retrieved!",
          });
        }
      );
  }

  refreshFirstNames() {
    this.resetFirstName();
    this.firstNames = [];
    this.branchService.getFull<Branch>({ id: this.branch.id }).then(
      (result) => {
        this.firstNames = result.branchFirstNames;
        this.hasLoadedFirstNames = true;
      },
      (error) => {
        this.hasLoadedFirstNames = false;
        this.addAlert({
          type: "danger",
          message: "First Names could not be retrieved!",
        });
      }
    );
  }

  refreshLastNames() {
    this.resetLastName();
    this.lastNames = [];
    this.branchService.getFull<Branch>({ id: this.branch.id }).then(
      (result) => {
        this.lastNames = result.branchLastNames;
        this.hasLoadedLastNames = true;
      },
      (error) => {
        this.hasLoadedLastNames = false;
        this.addAlert({
          type: "danger",
          message: "Last Names could not be retrieved!",
        });
      }
    );
  }

  setFirstName(id: string) {
    this.resetFirstName();
    this.selectedFirstName = this.firstNames.find((firstName) => {
      return firstName.id === id;
    });
  }

  setLastName(id: string) {
    this.resetLastName();
    this.selectedLastName = this.lastNames.find((lastName) => {
      return lastName.id === id;
    });
  }

  resetFirstName() {
    this.selectedFirstName = {
      id: "",
      name: null,
      description: null,
      imagePath: "",
      created: "",
      modified: "",
      isActive: false,
      branch: null,
    };
  }

  resetLastName() {
    this.selectedLastName = {
      id: "",
      name: null,
      description: null,
      imagePath: "",
      created: "",
      modified: "",
      isActive: false,
      branch: null,
    };
  }

  setName() {
    this.branchName = this.branch.name;
  }

  setPath() {
    this.branchImagePath = this.branch.imagePath
      ? this.branchService.baseUrl + this.branch.imagePath
      : null;
  }

  setModal(id?: string, title?: string, body?: string) {
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  patch(patch: Patch, field: string) {
    this.branchService.patch<Patch>([patch], { id: this.branch.id }).subscribe(
      (result) => {
        this.addAlert({
          type: "success",
          message: 'Field: "' + field + '" was updated successfully!',
        });
        this.setName();
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: 'Field: "' + field + '" could not be updated!',
        });
      }
    );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.branch.name },
      "Name"
    );
  }

  uploadFile(files) {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    this.filePath = fileToUpload.name;
    const formData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);

    this.branchService.upload<Upload>(formData).subscribe((result) => {
      this.branchImagePath = this.factionService.baseUrl + result.dbPath;
      this.patch(
        { op: "replace", path: "/imagePath", value: result.dbPath },
        "Image"
      );
    });
  }

  createFirstNameForm(content: any) {
    this.resetFirstName();
    this.setModal("", "Create a new First Name", "");
    this.modalService.open(content, { centered: true });
  }

  createFirstName() {
    this.selectedFirstName.description = this.selectedFirstName.name;
    this.branchFirstNameService
      .post<BranchFirstName>(this.selectedFirstName, {
        branchId: this.branch.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "First Name was created successfully!",
          });
          this.refreshFirstNames();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "First Name could not be created!",
          });
        }
      );
  }

  createLastNameForm(content: any) {
    this.resetLastName();
    this.setModal("", "Create a new Last Name", "");
    this.modalService.open(content, { centered: true });
  }

  createLastName() {
    this.selectedLastName.description = this.selectedLastName.name;
    this.branchLastNameService
      .post<BranchLastName>(this.selectedLastName, { branchId: this.branch.id })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Last Name was created successfully!",
          });
          this.refreshLastNames();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Last Name could not be created!",
          });
        }
      );
  }

  toggleFirstNameConfirm(content: any, value: string) {
    this.setFirstName(value);
    this.setModal(
      value,
      (this.selectedFirstName.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedFirstName.name,
      "Are you sure you want to " +
        (this.selectedFirstName.isActive ? "disable" : "enable") +
        ' First Name: "' +
        this.selectedFirstName.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleFirstName();
        }
      })
      .catch((res) => {});
  }

  toggleFirstName() {
    this.branchFirstNameService
      .patch<Patch>(
        [
          {
            op: "replace",
            path: "/isActive",
            value: String(!this.selectedFirstName.isActive),
          },
        ],
        { id: this.selectedFirstName.id }
      )
      .subscribe(
        (result) => {
          this.selectedFirstName.isActive = !this.selectedFirstName.isActive;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Field: "' +
              this.selectedFirstName.name +
              '" could not be updated!',
          });
        }
      );
  }

  toggleLastNameConfirm(content: any, value: string) {
    this.setLastName(value);
    this.setModal(
      value,
      (this.selectedLastName.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedLastName.name,
      "Are you sure you want to " +
        (this.selectedLastName.isActive ? "disable" : "enable") +
        ' Last Name: "' +
        this.selectedLastName.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleLastName();
        }
      })
      .catch((res) => {});
  }

  toggleLastName() {
    this.branchLastNameService
      .patch<Patch>(
        [
          {
            op: "replace",
            path: "/isActive",
            value: String(!this.selectedLastName.isActive),
          },
        ],
        { id: this.selectedLastName.id }
      )
      .subscribe(
        (result) => {
          this.selectedLastName.isActive = !this.selectedLastName.isActive;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Field: "' +
              this.selectedLastName.name +
              '" could not be updated!',
          });
        }
      );
  }

  viewFirstName(value: any) {
    this.router.navigate([
      "admin/factions/" +
        this.faction.id +
        "/branches/" +
        this.branch.id +
        "/first/" +
        value,
    ]);
  }

  viewLastName(value: any) {
    this.router.navigate([
      "admin/factions/" +
        this.faction.id +
        "/branches/" +
        this.branch.id +
        "/last/" +
        value,
    ]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
