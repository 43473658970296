import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class MatchService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getData<T>(): Promise<T> {
    var url = this.baseUrl + "api/Matches/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  get<Match>(ids): Promise<Match> {
    var url = this.baseUrl + "api/Matches/" + ids.id + this.getCacheBust;
    return this.http.get<Match>(url).toPromise();
  }

  getFull<Match>(ids): Promise<Match> {
    var url = this.baseUrl + "api/Matches/" + ids.id + "/Full" + this.getCacheBust;
    return this.http.get<Match>(url).toPromise();
  }

  put<Match>(item, ids): Observable<Match> {
    var url = this.baseUrl + "api/Matches/" + ids.id;
    return this.http.put<Match>(url, item);
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/Matches/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<Match>(item, ids): Observable<Match> {
    var url = this.baseUrl + "api/Matches/" + ids.simulationId + "/Origin/U/Number/" + ids.matchNumber;
    return this.http.post<Match>(url, item);
  }

  postHeuristics<Match>(item, ids): Observable<Match> {
    var url = this.baseUrl + "api/Matches/" + ids.simulationId + "/Origin/H/Number/" + ids.matchNumber;
    return this.http.post<Match>(url, item);
  }

  delete<Match>(ids): Observable<Match> {
    var url = this.baseUrl + "api/Matches/" + ids.id;
    return this.http.delete<Match>(url);
  }

  upload<Upload>(item): Observable<Upload> {
    var url = this.baseUrl + "api/Upload/";
    return this.http.post<Upload>(url, item);
  }
}
