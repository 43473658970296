import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class RosterModelService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  get<RosterModel>(ids): Promise<RosterModel> {
    var url = this.baseUrl + "api/RosterModels/" + ids.id + this.getCacheBust;
    return this.http.get<RosterModel>(url).toPromise();
  }

  getFull<RosterModel>(ids): Promise<RosterModel> {
    var url = this.baseUrl + "api/RosterModels/" + ids.id + "/Full" + this.getCacheBust;
    return this.http.get<RosterModel>(url).toPromise();
  }

  put<RosterModel>(item, ids): Observable<RosterModel> {
    var url = this.baseUrl + "api/RosterModels/" + ids.id;
    return this.http.put<RosterModel>(url, item);
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/RosterModels/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<RosterModel>(item, ids): Observable<RosterModel> {
    var url = this.baseUrl + "api/RosterModels/New/Model/" + ids.modelId + "/Roster/" + ids.rosterId + "/Wargear/" + ids.wargearId;
    return this.http.post<RosterModel>(url, item);
  }

  delete<RosterModel>(ids): Observable<RosterModel> {
    var url = this.baseUrl + "api/RosterModels/" + ids.id;
    return this.http.delete<RosterModel>(url);
  }
}
