import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Alert } from "src/app/models/alert.model";
import { Faction } from "src/app/models/faction.model";
import { FactionLastName } from "src/app/models/factionlastname.model";
import { Patch } from "src/app/models/patch.model";
import { FactionLastNameService } from "src/app/services/factionlastname.service";

@Component({
  selector: "app-factionlastname",
  templateUrl: "./factionlastname.component.html",
  preserveWhitespaces: true,
})
export class FactionLastNameComponent {
  faction: Faction;
  factionId: string = "";
  factionName: string = "Faction";

  lastName: FactionLastName;
  lastNameName: string = "LastName";

  alerts: Alert[] = [];

  filePath: string;

  hasLoadedLastName: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private factionLastNameService: FactionLastNameService
  ) {
    this.getLastName();
  }

  getLastName() {
    this.factionLastNameService
      .get<FactionLastName>({
        id: this.activatedRoute.snapshot.paramMap.get("lastNameId"),
      })
      .then(
        (result) => {
          this.lastName = result;
          this.faction = this.lastName.faction;
          this.factionId = this.faction.id;
          this.factionName = this.faction.name;
          this.hasLoadedLastName = true;
          this.setName();
        },
        (error) => {
          this.hasLoadedLastName = false;
          this.addAlert({
            type: "danger",
            message: "Last Name could not be retrieved!",
          });
        }
      );
  }

  setName() {
    this.lastNameName = this.lastName.name;
  }

  patch(patch: Patch, field: string) {
    this.factionLastNameService
      .patch<Patch>([patch], { id: this.lastName.id })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: 'Field: "' + field + '" was updated successfully!',
          });
          this.setName();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: 'Field: "' + field + '" could not be updated!',
          });
        }
      );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.lastName.name },
      "Name"
    );
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
