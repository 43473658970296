import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Attribute } from "src/app/models/attribute.model";
import { Faction } from "src/app/models/faction.model";
import { Model } from "src/app/models/model.model";
import { Patch } from "src/app/models/patch.model";
import { FactionService } from "src/app/services/faction.service";
import { ModelService } from "src/app/services/model.service";

@Component({
  selector: "app-models",
  templateUrl: "./models.component.html",
  preserveWhitespaces: true,
})
export class ModelsComponent {
  models = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalFactions: Faction[];
  modalTitle: string;
  modalBody: string;
  modalId: string;

  selectedModel: Model;

  Attributes: any;

  alerts: Alert[] = [];

  hasLoadedModels: boolean = false;

  constructor(
    private router: Router,
    private modelService: ModelService,
    private factionService: FactionService,
    private modalService: NgbModal
  ) {
    this.refreshModels();
  }

  refreshModels() {
    this.resetModel();
    this.models = [];
    this.modelService.getData<Model[]>().then(
      (result) => {
        this.models = result;
        this.hasLoadedModels = true;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Models could not be retrieved!",
        });
        this.hasLoadedModels = false;
      }
    );
  }

  setModel(id: string) {
    this.resetModel();
    this.selectedModel = this.models.find((model) => {
      return model.id === id;
    });
  }

  setModelModal(id?: string, title?: string, body?: string) {
    this.resetAttributes();
    this.factionService.getData<Faction[]>().then(
      (result) => {
        this.modalFactions = result;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Factions could not be retrieved!",
        });
      }
    );
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  resetAttributes() {
    this.Attributes = {
      move: "",
      weaponSkill: "",
      ballisticSkill: "",
      strength: "",
      toughness: "",
      wounds: "",
      attacks: "",
      leadership: "",
      save: "",
      maximum: "",
    };
  }

  resetModel() {
    this.selectedModel = {
      id: "",
      name: null,
      description: null,
      value: null,
      imagePath: "",
      created: "",
      modified: "",
      isActive: false,
      attributes: null,
      faction: null,
      wargear: null,
    };
  }

  createForm(content: any, value: string) {
    this.resetModel();
    this.setModelModal("", "Create a new Model", "");
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  createModel() {
    this.selectedModel.description = this.selectedModel.name;
    this.selectedModel.attributes = this.setAttributes();
    this.modelService
      .post<Model>(this.selectedModel, {
        factionId: this.selectedModel.faction.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Model was created successfully!",
          });
          this.refreshModels();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Model could not be created!",
          });
        }
      );
  }

  setAttributes(): Attribute[] {
    return [
      this.newAttribute("Move", "M", this.Attributes.move, 1),
      this.newAttribute("Weapon Skill", "WS", this.Attributes.weaponSkill, 2),
      this.newAttribute(
        "Ballistic Skill",
        "BS",
        this.Attributes.ballisticSkill,
        3
      ),
      this.newAttribute("Strength", "S", this.Attributes.strength, 4),
      this.newAttribute("Toughness", "T", this.Attributes.toughness, 5),
      this.newAttribute("Wounds", "W", this.Attributes.wounds, 6),
      this.newAttribute("Attacks", "A", this.Attributes.attacks, 7),
      this.newAttribute("Leadership", "Ld", this.Attributes.leadership, 8),
      this.newAttribute("Save", "Sv", this.Attributes.save, 9),
      this.newAttribute("Maximum", "Max", this.Attributes.maximum, 10),
      this.newAttribute("Flesh Wounds", "FW", "3", 11),
    ];
  }

  newAttribute(
    name: string,
    shortName: string,
    value: string,
    priority: number
  ): Attribute {
    var max = 0;
    var min = 0;
    var roll = 0;
    var sides = 0;
    var multiply = 1;
    var useParent = false;
    switch (true) {
      case value.indexOf("D") !== -1: {
        var values = value.split("D");
        min = Number(values[0]);
        max = min * Number(values[1]);
        roll = min;
        sides = Number(values[1]);
        break;
      }
      case value.indexOf("+") !== -1: {
        var values = value.split("+");
        min = Number(values[0]);
        roll = 1;
        sides = 6;
        break;
      }
      default: {
        max = value ? Number(value) : max;
        break;
      }
    }
    return {
      id: "",
      name: name,
      description: name,
      shortName: shortName,
      display: value,
      roll: roll,
      sides: sides,
      multiply: multiply,
      max: max,
      min: min,
      priority: priority,
      created: "",
      modified: "",
      isActive: true,
      useParent: useParent,
    };
  }

  deleteConfirm(content: any, value: string) {
    this.setModel(value);
    this.setModelModal(
      value,
      "Delete " + this.selectedModel.name,
      'Are you sure you want to delete Model: "' +
        this.selectedModel.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.deleteModel();
        }
      })
      .catch((res) => {});
  }

  deleteModel() {
    this.modelService.delete<Model>({ id: this.selectedModel.id }).subscribe(
      (result) => {
        this.addAlert({
          type: "success",
          message:
            'Model: "' +
            this.selectedModel.name +
            '" was deleted successfully!',
        });
        this.models = this.models.filter(
          ({ id }) => id !== this.selectedModel.id
        );
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message:
            'Model: "' + this.selectedModel.name + '" could not be deleted!',
        });
      }
    );
  }

  toggleModelConfirm(content: any, value: string) {
    this.setModel(value);
    this.setModelModal(
      value,
      (this.selectedModel.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedModel.name,
      "Are you sure you want to " +
        (this.selectedModel.isActive ? "disable" : "enable") +
        ' Model: "' +
        this.selectedModel.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleModel();
        }
      })
      .catch((res) => {});
  }

  toggleModel() {
    this.modelService
      .patch<Patch>(
        [
          {
            op: "replace",
            path: "/isActive",
            value: String(!this.selectedModel.isActive),
          },
        ],
        { id: this.selectedModel.id }
      )
      .subscribe(
        (result) => {
          this.selectedModel.isActive = !this.selectedModel.isActive;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Field: "' + this.selectedModel.name + '" could not be updated!',
          });
        }
      );
  }

  view(value: any) {
    this.router.navigate(["admin/models/" + value]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
