import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Faction } from "src/app/models/faction.model";
import { Model } from "src/app/models/model.model";
import { Patch } from "src/app/models/patch.model";
import { Wargear } from "src/app/models/wargear.model";
import { Weapon } from "src/app/models/weapon.model";
import { FactionService } from "src/app/services/faction.service";
import { ModelService } from "src/app/services/model.service";
import { WargearService } from "src/app/services/wargear.service";

@Component({
  selector: "app-wargear",
  templateUrl: "./wargear.component.html",
  preserveWhitespaces: true,
})
export class WargearComponent {
  weapons: Weapon[] = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalTitle: string;
  modalWeapons: Weapon[] = [];
  modalBody: string;
  modalId: string;

  selectedWeapon: Weapon;

  model: Model;
  modelId: string = "";
  modelName: string = "Model";

  wargear: Wargear;
  wargearName: string = "Wargear";

  alerts: Alert[] = [];

  hasLoadedModel: boolean = false;
  hasLoadedWargear: boolean = false;
  hasLoadedWeapons: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modelService: ModelService,
    private factionService: FactionService,
    private wargearService: WargearService,
    private modalService: NgbModal
  ) {
    this.getModel();
    this.getWargear();
  }

  getModel() {
    this.modelService
      .getFull<Model>({
        id: this.activatedRoute.snapshot.paramMap.get("modelId"),
      })
      .then(
        (result) => {
          this.model = result;
          this.modelId = this.model.id;
          this.modelName = this.model.name;
          this.hasLoadedModel = true;
        },
        (error) => {
          this.hasLoadedModel = false;
          this.addAlert({
            type: "danger",
            message: "Model could not be retrieved!",
          });
        }
      );
  }

  getWargear() {
    this.wargearService
      .getFull<Wargear>({
        id: this.activatedRoute.snapshot.paramMap.get("wargearId"),
      })
      .then(
        (result) => {
          this.wargear = result;
          this.hasLoadedWargear = true;
          this.weapons = this.wargear.weapons;
          this.hasLoadedWeapons = true;
          this.setName();
        },
        (error) => {
          this.hasLoadedWargear = false;
          this.hasLoadedWeapons = false;
          this.addAlert({
            type: "danger",
            message: "Wargear could not be retrieved!",
          });
        }
      );
  }

  refreshWeapons() {
    this.resetExistingWeapon();
    this.weapons = [];
    this.wargearService.getFull<Wargear>({ id: this.wargear.id }).then(
      (result) => {
        this.weapons = result.weapons;
        this.hasLoadedWeapons = true;
      },
      (error) => {
        this.hasLoadedWeapons = false;
        this.addAlert({
          type: "danger",
          message: "Weapon could not be retrieved!",
        });
      }
    );
  }

  setWeapon(id: string) {
    this.resetExistingWeapon();
    this.selectedWeapon = this.weapons.find((row) => {
      return row.id === id;
    });
  }

  setName() {
    this.wargearName = this.wargear.name;
  }

  patch(patch: Patch, field: string) {
    this.wargearService
      .patch<Patch>([patch], { id: this.wargear.id })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: 'Field: "' + field + '" was updated successfully!',
          });
          this.setName();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: 'Field: "' + field + '" could not be updated!',
          });
        }
      );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.wargear.name },
      "Name"
    );
  }

  setWeaponModal(id?: string, title?: string, body?: string) {
    this.modalWeapons = [];
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  setExistingWeaponModal(id?: string, title?: string, body?: string) {
    this.factionService.getFull<Faction>({ id: this.model.faction.id }).then(
      (result) => {
        this.modalWeapons = result.weapons.filter(
          (x) => !this.weapons.find((y) => y.id === x.id)
        );
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Weapon could not be retrieved!",
        });
      }
    );
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  resetExistingWeapon() {
    this.selectedWeapon = null;
  }

  addWeaponForm(content: any) {
    this.resetExistingWeapon();
    this.setExistingWeaponModal("", "Add a existing Weapon", "");
    this.modalService.open(content, { centered: true });
  }

  addWeapon() {
    this.wargearService
      .putAddExistingWeapon<Wargear>(this.selectedWeapon, {
        id: this.wargear.id,
        weaponId: this.selectedWeapon.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Weapon was added successfully!",
          });
          this.refreshWeapons();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Weapon could not be added!",
          });
        }
      );
  }

  deleteWeaponConfirm(content: any, value: string) {
    this.setWeapon(value);
    this.setWeaponModal(
      value,
      "Remove " + this.selectedWeapon.name,
      'Are you sure you want to remove Weapon: "' +
        this.selectedWeapon.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.deleteWeapon();
        }
      })
      .catch((res) => {});
  }

  deleteWeapon() {
    this.wargearService
      .putRemoveExistingWeapon<Wargear>(this.selectedWeapon, {
        id: this.wargear.id,
        weaponId: this.selectedWeapon.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message:
              'Weapon: "' +
              this.selectedWeapon.name +
              '" was removed successfully!',
          });
          this.weapons = this.weapons.filter(
            ({ id }) => id !== this.selectedWeapon.id
          );
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Weapon: "' +
              this.selectedWeapon.name +
              '" could not be removed!',
          });
        }
      );
  }

  viewWeapon(value: any) {
    this.router.navigate(["admin/weapons/" + value]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
