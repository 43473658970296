import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
  roles: Array<string>;

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.roles = route.data["roles"]
      ? (route.data["roles"] as Array<string>)
      : new Array<string>();
    return this.authService.canActivateProtectedRoutes$.pipe(
      tap((x) => {
        console.log(
          "You tried to go to " + state.url + " and this guard said " + x
        );
        if (!x) {
          this.router.navigate(["/auth/access"]);
        } else {
          if (
            !this.roles.every((v: string) =>
              this.authService.identityClaim("role").includes(v)
            )
          ) {
            this.router.navigate(["/error/403"]);
          }
        }
      })
    );
  }
}
