import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { interval, Subscription } from "rxjs";
import { Alert } from "src/app/models/alert.model";
import { GameMode } from "src/app/models/gamemode.model";
import { Match } from "src/app/models/match.model";
import { MatchFact } from "src/app/models/matchfact.model";
import { Patch } from "src/app/models/patch.model";
import { Roster } from "src/app/models/roster.model";
import { Simulation } from "src/app/models/simulation.model";
import { GameModeService } from "src/app/services/gamemode.service";
import { MatchService } from "src/app/services/match.service";
import { SimulationService } from "src/app/services/simulation.service";

@Component({
  selector: "app-heuristics",
  templateUrl: "./heuristics.component.html",
  preserveWhitespaces: true,
})
export class HeuristicsComponent {
  gameModes = [];

  subscriptionSimulation: Subscription;
  subscription: Subscription;

  selectedGameMode: GameMode;
  selectedMatch: Match;

  simulation: Simulation;

  blue: Roster;

  matchFacts: MatchFact[];

  matchNumber: number = 0;

  red: Roster;

  alerts: Alert[] = [];

  hasLoadedBlue: boolean;
  hasLoadedGameModes: boolean;
  hasLoadedRed: boolean;
  hasLoadedSimulation: boolean;

  isPaused: boolean = true;
  isSuspended: boolean = true;

  constructor(
    private router: Router,
    private gameModeService: GameModeService,
    private matchService: MatchService,
    private simulationService: SimulationService
  ) {
    this.refreshGameModes();
  }

  getSimulation() {
    this.subscriptionSimulation.unsubscribe();
    this.simulationService
      .postHeuristics<Simulation>(this.simulation, {
        gameModeId: this.selectedGameMode.id,
      })
      .subscribe(
        (result) => {
          this.simulationService.getFull<Simulation>({ id: result.id }).then(
            (result) => {
              this.simulation = result;
              this.hasLoadedSimulation = true;
              this.blue = this.simulation.blueRoster;
              this.hasLoadedBlue = true;
              this.red = this.simulation.redRoster;
              this.hasLoadedRed = true;
              this.matchFacts =
                this.simulation.dimensions[
                  this.simulation.dimensions.length - 1
                ].matchFacts;
              this.play();
            },
            (error) => {
              this.hasLoadedSimulation = false;
              this.hasLoadedBlue = false;
              this.hasLoadedRed = false;
              this.addAlert({
                type: "danger",
                message: "Simulation could not be retrieved!",
              });
            }
          );
        },
        (error) => {
          this.hasLoadedSimulation = false;
          this.addAlert({
            type: "danger",
            message: "Simulation could not be created!",
          });
        }
      );
  }

  refreshGameModes() {
    this.gameModes = [];
    this.gameModeService.getData<GameMode[]>().then(
      (result) => {
        this.gameModes = result;
        this.hasLoadedGameModes = true;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Game Modes could not be retrieved!",
        });
      }
    );
  }

  resetMatch() {
    this.selectedMatch = {
      id: "",
      name: "",
      description: "",
      created: "",
      modified: "",
      isActive: true,
      matchLogs: null,
      simulation: null,
    };
  }

  resetSimulation() {
    this.simulation = {
      id: "",
      name: "",
      description: "",
      created: "",
      modified: "",
      isActive: true,
      isCompleted: false,
      blueRoster: null,
      matches: null,
      redRoster: null,
      gameMode: null,
      dimensions: null,
      rosterType: null,
      user: null,
    };
  }

  startQueue() {
    this.isSuspended = false;
    this.continueQueue();
  }

  stopQueue() {
    this.pause();
    this.isSuspended = true;
    this.subscriptionSimulation.unsubscribe();
  }

  play() {
    this.isPaused = false;
    this.continue();
  }

  pause() {
    this.isPaused = true;
    this.subscription.unsubscribe();
  }

  complete() {
    this.setSimulationCompleted();
  }

  continueQueue() {
    const source = interval(100);
    this.resetMatch();
    this.resetSimulation();
    this.subscriptionSimulation = source.subscribe((val) =>
      this.getSimulation()
    );
  }

  continue() {
    const source = interval(100);
    this.subscription = source.subscribe((val) => this.createMatch());
    this.matchNumber++;
  }

  setSimulationCompleted() {
    this.simulationService
      .patch<Patch>([{ op: "replace", path: "/isCompleted", value: true }], {
        id: this.simulation.id,
      })
      .subscribe(
        (result) => {
          this.simulation.isCompleted = true;
          if (!this.isSuspended) this.continueQueue();
          else this.pause();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Simulation could not be completed!",
          });
        }
      );
  }

  createMatch() {
    this.subscription.unsubscribe();

    if (this.router.url != "/heuristics/queue") this.stopQueue();
    else if (
      !this.isSuspended &&
      !this.isPaused &&
      this.matchFacts.length < 200
    )
      this.matchService
        .postHeuristics<MatchFact[]>(this.selectedMatch, {
          simulationId: this.simulation.id,
          matchNumber: this.matchNumber,
        })
        .subscribe(
          (result) => {
            for (let key in result) {
              this.matchFacts.push(result[key]);
            }
            this.continue();
          },
          (error) => {
            this.pause();
            this.addAlert({
              type: "danger",
              message: "Match could not be created!",
            });
          }
        );

    if (this.matchFacts.length > 198) this.complete();
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
