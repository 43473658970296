import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NarikCustomValidatorsModule } from "@narik/custom-validators";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgApexchartsModule } from "ng-apexcharts";
import { ChartsModule } from "ng2-charts";
import { MatchService } from "src/app/services/match.service";
import { MatchFactService } from "src/app/services/matchfact.service";
import { SimulationService } from "src/app/services/simulation.service";
import { HistoryComponent } from "./history/history.component";
import { ProcessComponent } from "./process/process.component";
import { ResultLogComponent } from "./resultlog/resultlog.component";
import { ResultsComponent } from "./results/results.component";
import { SimulationRoutingModule } from "./simulation-routing.module";
import { SimulationComponent } from "./simulation/simulation.component";

@NgModule({
  declarations: [
    ResultsComponent,
    SimulationComponent,
    ProcessComponent,
    HistoryComponent,
    ResultLogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NarikCustomValidatorsModule,
    SimulationRoutingModule,
    NgbModule,
    NgxDatatableModule,
    NgSelectModule,
    NgbModule,
    NgApexchartsModule,
    ChartsModule,
  ],
  bootstrap: [],
  providers: [SimulationService, MatchService, MatchFactService],
})
export class SimulationModule {}
