import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FeatherIconDirective } from "./feather-icon.directive";

@NgModule({
  declarations: [FeatherIconDirective],
  imports: [CommonModule],
  exports: [FeatherIconDirective],
})
export class FeatherIconModule {}
