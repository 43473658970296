import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AccessComponent } from "./access/access.component";
import { AuthRoutingModule } from "./auth-routing.module";

@NgModule({
  declarations: [AccessComponent],
  imports: [FormsModule, AuthRoutingModule],
  bootstrap: [],
})
export class AuthModule {
  title = "Login | UBWHS";

  constructor() {}
}
