import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Simulation } from "src/app/models/simulation.model";
import { SimulationService } from "src/app/services/simulation.service";

@Component({
  selector: "app-simulations",
  templateUrl: "./simulations.component.html",
  preserveWhitespaces: true,
})
export class SimulationsComponent {
  simulations = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  alerts: Alert[] = [];

  hasLoadedSimulations: boolean = false;

  constructor(
    private router: Router,
    private simulationService: SimulationService
  ) {
    this.refreshSimulations();
  }

  refreshSimulations() {
    this.simulations = [];
    this.simulationService.getDataCompleted<Simulation[]>().then(
      (result) => {
        this.simulations = result;
        this.hasLoadedSimulations = true;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Simulations could not be retrieved!",
        });
      }
    );
  }

  viewSimulation(value: any) {
    this.router.navigate(["simulation/history/" + value]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
