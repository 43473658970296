import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class SimulationService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getData<T>(): Promise<T> {
    var url = this.baseUrl + "api/Simulations/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  getDataGameModeFacts<T>(): Promise<T> {
    var url = this.baseUrl + "api/Simulations/GameModeFacts/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  getDataMatchFacts<T>(): Promise<T> {
    var url = this.baseUrl + "api/Simulations/MatchFacts/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  getDataModelFacts<T>(): Promise<T> {
    var url = this.baseUrl + "api/Simulations/ModelFacts/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  getDataRosterFacts<T>(): Promise<T> {
    var url = this.baseUrl + "api/Simulations/RosterFacts/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  getDataWargearFacts<T>(): Promise<T> {
    var url = this.baseUrl + "api/Simulations/WargearFacts/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  getDataCompleted<T>(): Promise<T> {
    var url = this.baseUrl + "api/Simulations/Completed/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  getDataHeuristics<T>(): Promise<T> {
    var url = this.baseUrl + "api/Simulations/Heuristics/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  getDataUser<T>(): Promise<T> {
    var url = this.baseUrl + "api/Simulations/User/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  get<Simulation>(ids): Promise<Simulation> {
    var url = this.baseUrl + "api/Simulations/" + ids.id + this.getCacheBust;
    return this.http.get<Simulation>(url).toPromise();
  }

  getFull<Simulation>(ids): Promise<Simulation> {
    var url = this.baseUrl + "api/Simulations/" + ids.id + "/Full" + this.getCacheBust;
    return this.http.get<Simulation>(url).toPromise();
  }

  put<Simulation>(item, ids): Observable<Simulation> {
    var url = this.baseUrl + "api/Simulations/" + ids.id;
    return this.http.put<Simulation>(url, item);
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/Simulations/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<Simulation>(item, ids): Observable<Simulation> {
    var url = this.baseUrl + "api/Simulations/New/Blue/" + ids.blueId + "/Red/" + ids.redId + "/GameMode/" + ids.gameModeId + "/Type/" + ids.rosterTypeId;
    return this.http.post<Simulation>(url, item);
  }

  postHeuristics<Simulation>(item, ids): Observable<Simulation> {
    var url = this.baseUrl + "api/Simulations/New/GameMode/" + ids.gameModeId;
    return this.http.post<Simulation>(url, item);
  }

  delete<Simulation>(ids): Observable<Simulation> {
    var url = this.baseUrl + "api/Simulations/" + ids.id;
    return this.http.delete<Simulation>(url);
  }

  upload<Upload>(item): Observable<Upload> {
    var url = this.baseUrl + "api/Upload/";
    return this.http.post<Upload>(url, item);
  }
}
