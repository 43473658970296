import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RosterComponent } from "./roster/roster.component";
import { RostersComponent } from "./rosters/rosters.component";

const routes: Routes = [
  {
    path: "rosters",
    component: RostersComponent,
  },
  {
    path: "rosters/:rosterId",
    component: RosterComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
