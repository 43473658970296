import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class WargearService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getData<T>(): Promise<T> {
    var url = this.baseUrl + "api/Wargear/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  get<Wargear>(ids): Promise<Wargear> {
    var url = this.baseUrl + "api/Wargear/" + ids.id + this.getCacheBust;
    return this.http.get<Wargear>(url).toPromise();
  }

  getFull<Wargear>(ids): Promise<Wargear> {
    var url =
      this.baseUrl + "api/Wargear/" + ids.id + "/Full" + this.getCacheBust;
    return this.http.get<Wargear>(url).toPromise();
  }

  put<Wargear>(item, ids): Observable<Wargear> {
    var url = this.baseUrl + "api/Wargear/" + ids.id;
    return this.http.put<Wargear>(url, item);
  }

  putAddExistingWeapon<Wargear>(item, ids): Observable<Wargear> {
    var url = this.baseUrl + "api/Wargear/" + ids.id + "/Weapon/" + ids.weaponId + "/Add";
    return this.http.put<Wargear>(url, item);
  }

  putRemoveExistingWeapon<Wargear>(item, ids): Observable<Wargear> {
    var url = this.baseUrl + "api/Wargear/" + ids.id + "/Weapon/" + ids.weaponId + "/Remove";
    return this.http.put<Wargear>(url, item);
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/Wargear/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<Wargear>(item, ids): Observable<Wargear> {
    var url = this.baseUrl + "api/Wargear/New/Faction/" + ids.factionId;
    return this.http.post<Wargear>(url, item);
  }

  delete<Wargear>(ids): Observable<Wargear> {
    var url = this.baseUrl + "api/Wargear/" + ids.id;
    return this.http.delete<Wargear>(url);
  }
}
