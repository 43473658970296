import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class RosterTypeService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getData<T>(): Promise<T> {
    var url = this.baseUrl + "api/RosterTypes/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  get<RosterType>(ids): Promise<RosterType> {
    var url = this.baseUrl + "api/RosterTypes/" + ids.id + this.getCacheBust;
    return this.http.get<RosterType>(url).toPromise();
  }

  getFull<RosterType>(ids): Promise<RosterType> {
    var url = this.baseUrl + "api/RosterTypes/" + ids.id + "/Full" + this.getCacheBust;
    return this.http.get<RosterType>(url).toPromise();
  }

  put<RosterType>(item, ids): Observable<RosterType> {
    var url = this.baseUrl + "api/RosterTypes/" + ids.id;
    return this.http.put<RosterType>(url, item);
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/RosterTypes/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<RosterType>(item, ids): Observable<RosterType> {
    var url = this.baseUrl + "api/RosterTypes/New/GameMode/" + ids.gameModeId;
    return this.http.post<RosterType>(url, item);
  }

  delete<RosterType>(ids): Observable<RosterType> {
    var url = this.baseUrl + "api/RosterTypes/" + ids.id;
    return this.http.delete<RosterType>(url);
  }
}
