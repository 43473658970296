import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class PhaseService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getData<T>(ids): Promise<T> {
    var url = this.baseUrl + "api/Phases/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  get<Phase>(ids): Promise<Phase> {
    var url = this.baseUrl + "api/Phases/" + ids.id + this.getCacheBust;
    return this.http.get<Phase>(url).toPromise();
  }

  getFull<Phase>(ids): Promise<Phase> {
    var url = this.baseUrl + "api/Phases/" + ids.id + "/Full" + this.getCacheBust;
    return this.http.get<Phase>(url).toPromise();
  }

  put<Phase>(item, ids): Observable<Phase> {
    var url = this.baseUrl + "api/Phases/" + ids.id;
    return this.http.put<Phase>(url, item);
  }

  putPriority<Phase>(item, ids): Observable<Phase> {
    var url = this.baseUrl + "api/Phases/" + ids.id + "/Target/" + ids.targetId;
    return this.http.put<Phase>(url, item);
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/Phases/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<Phase>(item, ids): Observable<Phase> {
    var url = this.baseUrl + "api/Phases/" + ids.id + "/GameMode/" + ids.gameModeId;
    return this.http.post<Phase>(url, item);
  }

  delete<Phase>(ids): Observable<Phase> {
    var url = this.baseUrl + "api/Phases/" + ids.id;
    return this.http.delete<Phase>(url);
  }
}
