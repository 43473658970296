import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Branch } from "src/app/models/branch.model";
import { Faction } from "src/app/models/faction.model";
import { FactionFirstName } from "src/app/models/factionfirstname.model";
import { FactionLastName } from "src/app/models/factionlastname.model";
import { Patch } from "src/app/models/patch.model";
import { Upload } from "src/app/models/upload.model";
import { BranchService } from "src/app/services/branch.service";
import { FactionService } from "src/app/services/faction.service";
import { FactionFirstNameService } from "src/app/services/factionfirstname.service";
import { FactionLastNameService } from "src/app/services/factionlastname.service";

@Component({
  selector: "app-faction",
  templateUrl: "./faction.component.html",
  preserveWhitespaces: true,
})
export class FactionComponent {
  branches = [];
  firstNames = [];
  lastNames = [];

  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalTitle: string;
  modalBody: string;
  modalId: string;

  selectedBranch: Branch;
  selectedFirstName: FactionFirstName;
  selectedLastName: FactionLastName;

  faction: Faction;
  factionName: string = "Faction";
  factionImagePath: string;

  alerts: Alert[] = [];

  filePath: string;

  hasLoadedFaction: boolean = false;
  hasLoadedFirstNames: boolean = false;
  hasLoadedLastNames: boolean = false;
  hasLoadedBranches: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private factionService: FactionService,
    private factionFirstNameService: FactionFirstNameService,
    private factionLastNameService: FactionLastNameService,
    private branchService: BranchService,
    private modalService: NgbModal
  ) {
    this.getFaction();
  }

  getFaction() {
    this.factionService
      .getFull<Faction>({
        id: this.activatedRoute.snapshot.paramMap.get("factionId"),
      })
      .then(
        (result) => {
          this.faction = result;
          this.hasLoadedFaction = true;
          this.setName();
          this.setPath();
          this.branches = this.faction.branches;
          this.hasLoadedBranches = true;
          this.firstNames = this.faction.factionFirstNames;
          this.hasLoadedFirstNames = true;
          this.lastNames = this.faction.factionLastNames;
          this.hasLoadedLastNames = true;
        },
        (error) => {
          this.hasLoadedFaction = false;
          this.hasLoadedBranches = false;
          this.hasLoadedFirstNames = false;
          this.hasLoadedLastNames = false;
          this.addAlert({
            type: "danger",
            message: "Faction could not be retrieved!",
          });
        }
      );
  }

  refreshBranches() {
    this.resetBranch();
    this.branches = [];
    this.factionService.getFull<Faction>({ id: this.faction.id }).then(
      (result) => {
        this.branches = result.branches;
        this.hasLoadedBranches = true;
      },
      (error) => {
        this.hasLoadedBranches = false;
        this.addAlert({
          type: "danger",
          message: "Branches could not be retrieved!",
        });
      }
    );
  }

  refreshFirstNames() {
    this.resetFirstName();
    this.firstNames = [];
    this.factionService.getFull<Faction>({ id: this.faction.id }).then(
      (result) => {
        this.firstNames = result.factionFirstNames;
        this.hasLoadedFirstNames = true;
      },
      (error) => {
        this.hasLoadedFirstNames = false;
        this.addAlert({
          type: "danger",
          message: "First Names could not be retrieved!",
        });
      }
    );
  }

  refreshLastNames() {
    this.resetLastName();
    this.lastNames = [];
    this.factionService.getFull<Faction>({ id: this.faction.id }).then(
      (result) => {
        this.lastNames = result.factionLastNames;
        this.hasLoadedLastNames = true;
      },
      (error) => {
        this.hasLoadedLastNames = false;
        this.addAlert({
          type: "danger",
          message: "Last Names could not be retrieved!",
        });
      }
    );
  }

  setBranch(id: string) {
    this.resetBranch();
    this.selectedBranch = this.branches.find((branch) => {
      return branch.id === id;
    });
  }

  setFirstName(id: string) {
    this.resetFirstName();
    this.selectedFirstName = this.firstNames.find((firstName) => {
      return firstName.id === id;
    });
  }

  setLastName(id: string) {
    this.resetLastName();
    this.selectedLastName = this.lastNames.find((lastName) => {
      return lastName.id === id;
    });
  }

  resetBranch() {
    this.selectedBranch = {
      id: "",
      name: null,
      description: null,
      colour: "",
      imagePath: "",
      created: "",
      modified: "",
      isActive: false,
      branchFirstNames: null,
      branchLastNames: null,
      faction: null,
    };
  }

  resetFirstName() {
    this.selectedFirstName = {
      id: "",
      name: null,
      description: null,
      imagePath: "",
      created: "",
      modified: "",
      isActive: false,
      faction: null,
    };
  }

  resetLastName() {
    this.selectedLastName = {
      id: "",
      name: null,
      description: null,
      imagePath: "",
      created: "",
      modified: "",
      isActive: false,
      faction: null,
    };
  }

  setName() {
    this.factionName = this.faction.name;
  }

  setPath() {
    this.factionImagePath = this.faction.imagePath
      ? this.factionService.baseUrl + this.faction.imagePath
      : null;
  }

  setModal(id?: string, title?: string, body?: string) {
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  patch(patch: Patch, field: string) {
    this.factionService
      .patch<Patch>([patch], { id: this.faction.id })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: 'Field: "' + field + '" was updated successfully!',
          });
          this.setName();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: 'Field: "' + field + '" could not be updated!',
          });
        }
      );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.faction.name },
      "Name"
    );
  }

  editColour() {
    this.patch(
      { op: "replace", path: "/colour", value: this.faction.colour },
      "Colour"
    );
  }

  uploadFile(files) {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    this.filePath = fileToUpload.name;
    const formData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);

    this.factionService.upload<Upload>(formData).subscribe((result) => {
      this.factionImagePath = this.factionService.baseUrl + result.dbPath;
      this.patch(
        { op: "replace", path: "/imagePath", value: result.dbPath },
        "Image"
      );
    });
  }

  createBranchForm(content: any) {
    this.resetBranch();
    this.setModal("", "Create a new Branch", "");
    this.modalService.open(content, { centered: true });
  }

  createBranch() {
    this.selectedBranch.description = this.selectedBranch.name;
    this.branchService
      .post<Branch>(this.selectedBranch, { factionId: this.faction.id })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Branch was created successfully!",
          });
          this.refreshBranches();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Branch could not be created!",
          });
        }
      );
  }

  createFirstNameForm(content: any) {
    this.resetFirstName();
    this.setModal("", "Create a new First Name", "");
    this.modalService.open(content, { centered: true });
  }

  createFirstName() {
    this.selectedFirstName.description = this.selectedFirstName.name;
    this.factionFirstNameService
      .post<FactionFirstName>(this.selectedFirstName, {
        factionId: this.faction.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "First Name was created successfully!",
          });
          this.refreshFirstNames();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "First Name could not be created!",
          });
        }
      );
  }

  createLastNameForm(content: any) {
    this.resetLastName();
    this.setModal("", "Create a new Last Name", "");
    this.modalService.open(content, { centered: true });
  }

  createLastName() {
    this.selectedLastName.description = this.selectedLastName.name;
    this.factionLastNameService
      .post<FactionLastName>(this.selectedLastName, {
        factionId: this.faction.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Last Name was created successfully!",
          });
          this.refreshLastNames();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Last Name could not be created!",
          });
        }
      );
  }

  toggleBranchConfirm(content: any, value: string) {
    this.setBranch(value);
    this.setModal(
      value,
      (this.selectedBranch.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedBranch.name,
      "Are you sure you want to " +
        (this.selectedBranch.isActive ? "disable" : "enable") +
        ' Branch: "' +
        this.selectedBranch.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleBranch();
        }
      })
      .catch((res) => {});
  }

  toggleBranch() {
    if (this.selectedBranch.imagePath != "")
      this.branchService
        .patch<Patch>(
          [
            {
              op: "replace",
              path: "/isActive",
              value: String(!this.selectedBranch.isActive),
            },
          ],
          { id: this.selectedBranch.id }
        )
        .subscribe(
          (result) => {
            this.selectedBranch.isActive = !this.selectedBranch.isActive;
          },
          (error) => {
            this.addAlert({
              type: "danger",
              message:
                'Field: "' +
                this.selectedBranch.name +
                '" could not be updated!',
            });
          }
        );
    else
      this.addAlert({
        type: "danger",
        message:
          '"' +
          this.selectedBranch.name +
          '" cannot be activated! Branch Image is required!',
      });
  }

  toggleFirstNameConfirm(content: any, value: string) {
    this.setFirstName(value);
    this.setModal(
      value,
      (this.selectedFirstName.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedFirstName.name,
      "Are you sure you want to " +
        (this.selectedFirstName.isActive ? "disable" : "enable") +
        ' First Name: "' +
        this.selectedFirstName.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleFirstName();
        }
      })
      .catch((res) => {});
  }

  toggleFirstName() {
    this.factionFirstNameService
      .patch<Patch>(
        [
          {
            op: "replace",
            path: "/isActive",
            value: String(!this.selectedFirstName.isActive),
          },
        ],
        { id: this.selectedFirstName.id }
      )
      .subscribe(
        (result) => {
          this.selectedFirstName.isActive = !this.selectedFirstName.isActive;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Field: "' +
              this.selectedFirstName.name +
              '" could not be updated!',
          });
        }
      );
  }

  toggleLastNameConfirm(content: any, value: string) {
    this.setLastName(value);
    this.setModal(
      value,
      (this.selectedLastName.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedLastName.name,
      "Are you sure you want to " +
        (this.selectedLastName.isActive ? "disable" : "enable") +
        ' Last Name: "' +
        this.selectedLastName.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleLastName();
        }
      })
      .catch((res) => {});
  }

  toggleLastName() {
    this.factionLastNameService
      .patch<Patch>(
        [
          {
            op: "replace",
            path: "/isActive",
            value: String(!this.selectedLastName.isActive),
          },
        ],
        { id: this.selectedLastName.id }
      )
      .subscribe(
        (result) => {
          this.selectedLastName.isActive = !this.selectedLastName.isActive;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Field: "' +
              this.selectedLastName.name +
              '" could not be updated!',
          });
        }
      );
  }

  viewBranch(value: any) {
    this.router.navigate([
      "admin/factions/" + this.faction.id + "/branches/" + value,
    ]);
  }

  viewFirstName(value: any) {
    this.router.navigate([
      "admin/factions/" + this.faction.id + "/first/" + value,
    ]);
  }

  viewLastName(value: any) {
    this.router.navigate([
      "admin/factions/" + this.faction.id + "/last/" + value,
    ]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
