import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Branch } from "src/app/models/branch.model";
import { Faction } from "src/app/models/faction.model";
import { Model } from "src/app/models/model.model";
import { Patch } from "src/app/models/patch.model";
import { Roster } from "src/app/models/roster.model";
import { RosterModel } from "src/app/models/rostermodel.model";
import { BranchService } from "src/app/services/branch.service";
import { FactionService } from "src/app/services/faction.service";
import { RosterService } from "src/app/services/roster.service";
import { RosterModelService } from "src/app/services/rostermodel.service";

@Component({
  selector: "app-roster",
  templateUrl: "./roster.component.html",
  preserveWhitespaces: true,
})
export class RosterComponent {
  rosterModels = [];
  factionFirstNames = [];
  factionLastNames = [];
  branchFirstNames = [];
  branchLastNames = [];

  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalModels: Model[];
  modalTitle: string;
  modalBody: string;
  modalId: string;

  selectedRosterModel: RosterModel;

  roster: Roster;
  rosterName: string = "Roster";

  alerts: Alert[] = [];

  hasLoadedRoster: boolean = false;
  hasLoadedRosterModels: boolean = false;
  hasLoadedBranchNames: boolean = false;
  hasLoadedFactionNames: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private branchService: BranchService,
    private factionService: FactionService,
    private rosterService: RosterService,
    private rosterModelService: RosterModelService,
    private modalService: NgbModal
  ) {
    this.getRoster();
  }

  getRoster() {
    this.rosterService
      .getFull<Roster>({
        id: this.activatedRoute.snapshot.paramMap.get("rosterId"),
      })
      .then(
        (result) => {
          this.roster = result;
          this.hasLoadedRoster = true;
          this.setName();
          this.rosterModels = this.roster.rosterModels;
          this.hasLoadedRosterModels = true;
          this.getBranchNames();
          this.getFactionNames();
        },
        (error) => {
          this.hasLoadedRoster = false;
          this.hasLoadedRosterModels = false;
          this.addAlert({
            type: "danger",
            message: "Roster could not be retrieved!",
          });
        }
      );
  }

  refreshRosterModels() {
    this.rosterModels = [];
    this.rosterService.getFull<Roster>({ id: this.roster.id }).then(
      (result) => {
        this.rosterModels = result.rosterModels;
        this.hasLoadedRosterModels = true;
        this.roster.value = result.rosterModels.reduce(
          (accum, item) => accum + item.value,
          0
        );
        this.editValue();
      },
      (error) => {
        this.hasLoadedRosterModels = false;
        this.addAlert({
          type: "danger",
          message: "Roster Models could not be retrieved!",
        });
      }
    );
    this.resetRosterModel();
  }

  getBranchNames() {
    this.branchFirstNames = [];
    this.branchLastNames = [];
    this.branchService.getFull<Branch>({ id: this.roster.branch.id }).then(
      (result) => {
        this.branchFirstNames = result.branchFirstNames;
        this.branchLastNames = result.branchLastNames;
        this.hasLoadedBranchNames = true;
      },
      (error) => {
        this.hasLoadedBranchNames = false;
        this.addAlert({
          type: "danger",
          message: "Branch Names could not be retrieved!",
        });
      }
    );
  }

  getFactionNames() {
    this.factionFirstNames = [];
    this.factionLastNames = [];
    this.factionService.getFull<Faction>({ id: this.roster.faction.id }).then(
      (result) => {
        this.factionFirstNames = result.factionFirstNames;
        this.factionLastNames = result.factionLastNames;
        this.hasLoadedFactionNames = true;
      },
      (error) => {
        this.hasLoadedFactionNames = false;
        this.addAlert({
          type: "danger",
          message: "Faction Names could not be retrieved!",
        });
      }
    );
  }

  setRosterModel(id: string) {
    this.resetRosterModel();
    this.selectedRosterModel = this.rosterModels.find((roster) => {
      return roster.id === id;
    });
  }

  setRosterModelModal(id?: string, title?: string, body?: string) {
    this.factionService.getFull<Faction>({ id: this.roster.faction.id }).then(
      (result) => {
        this.modalModels = result.models;
        this.hasLoadedRosterModels = true;
      },
      (error) => {
        this.hasLoadedRosterModels = false;
        this.addAlert({
          type: "danger",
          message: "Models could not be retrieved!",
        });
      }
    );
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  resetRosterModel() {
    this.selectedRosterModel = {
      id: "",
      name: null,
      description: null,
      value: 0,
      created: "",
      modified: "",
      isActive: true,
      model: null,
      wargear: null,
    };
  }

  setName() {
    this.rosterName = this.roster.name;
  }

  defaultModelName() {
    this.selectedRosterModel.name =
      this.factionFirstNames.length > 0
        ? this.factionFirstNames[
            Math.floor(Math.random() * this.factionFirstNames.length)
          ].name
        : this.branchFirstNames[
            Math.floor(Math.random() * this.branchFirstNames.length)
          ].name;
    this.selectedRosterModel.name +=
      this.factionFirstNames.length > 0
        ? " " +
          this.factionLastNames[
            Math.floor(Math.random() * this.factionLastNames.length)
          ].name
        : " " +
          this.branchLastNames[
            Math.floor(Math.random() * this.branchLastNames.length)
          ].name;
  }

  createRosterModelForm(content: any, value: string) {
    this.resetRosterModel();
    this.setRosterModelModal("", "Add a new Model", "");
    this.modalService.open(content, { centered: true });
  }

  createRosterModel() {
    this.selectedRosterModel.description = this.selectedRosterModel.name;
    this.selectedRosterModel.value =
      this.selectedRosterModel.model.value +
      this.selectedRosterModel.wargear.value;
    if (this.selectedRosterModel.value + this.roster.value <= this.roster.rosterType.value)
      this.rosterModelService
        .post<RosterModel>(this.selectedRosterModel, {
          modelId: this.selectedRosterModel.model.id,
          rosterId: this.roster.id,
          wargearId: this.selectedRosterModel.wargear.id,
        })
        .subscribe(
          (result) => {
            this.addAlert({
              type: "success",
              message: "Model was added successfully!",
            });
            this.refreshRosterModels();
          },
          (error) => {
            this.addAlert({
              type: "danger",
              message: "Model could not be added!",
            });
          }
        );
    else
      this.addAlert({
        type: "danger",
        message: "Adding this model will exceed the roster's value limit!",
      });
  }

  deleteRosterModelConfirm(content: any, value: string) {
    this.setRosterModel(value);
    this.setRosterModelModal(
      value,
      "Delete " + this.selectedRosterModel.name,
      'Are you sure you want to delete Model: "' +
        this.selectedRosterModel.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.deleteRosterModel();
        }
      })
      .catch((res) => {});
  }

  deleteRosterModel() {
    this.rosterModelService
      .delete<RosterModel>({ id: this.selectedRosterModel.id })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message:
              'Model: "' +
              this.selectedRosterModel.name +
              '" was deleted successfully!',
          });
          this.refreshRosterModels();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Model: "' +
              this.selectedRosterModel.name +
              '" could not be deleted!',
          });
        }
      );
  }

  patch(patch: Patch, field: string) {
    this.rosterService.patch<Patch>([patch], { id: this.roster.id }).subscribe(
      (result) => {
        this.setName();
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: 'Field: "' + field + '" could not be updated!',
        });
      }
    );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.roster.name },
      "Name"
    );
  }

  editValue() {
    this.patch(
      { op: "replace", path: "/value", value: this.roster.value },
      "Value"
    );
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
