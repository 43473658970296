import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Patch } from "src/app/models/patch.model";
import { Weapon } from "src/app/models/weapon.model";
import { WeaponGroup } from "src/app/models/weapongroup.model";
import { WeaponProfileModeType } from "src/app/models/weaponprofilemodetype.model";
import { WeaponService } from "src/app/services/weapon.service";
import { WeaponGroupService } from "src/app/services/weapongroup.service";
import { WeaponProfileModeTypeService } from "src/app/services/weaponprofilemodetype.service";

@Component({
  selector: "app-weapons",
  templateUrl: "./weapons.component.html",
  preserveWhitespaces: true,
})
export class WeaponsComponent {
  weapons = [];
  groups = [];
  types = [];

  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalGroups: WeaponGroup[];
  modalTypes: WeaponProfileModeType[];
  modalTitle: string;
  modalBody: string;
  modalId: string;

  selectedWeapon: Weapon;

  selectedGroup: WeaponGroup;

  selectedType: WeaponProfileModeType;

  alerts: Alert[] = [];

  hasLoadedWeapons: boolean = false;

  hasLoadedGroups: boolean = false;

  hasLoadedTypes: boolean = false;

  constructor(
    private router: Router,
    private weaponService: WeaponService,
    private groupService: WeaponGroupService,
    private typeService: WeaponProfileModeTypeService,
    private modalService: NgbModal
  ) {
    this.refreshAll();
  }

  refreshAll() {
    this.refreshWeapons();
    this.refreshGroups();
    this.refreshTypes();
  }

  refreshWeapons() {
    this.resetWeapon();
    this.weapons = [];
    this.weaponService.getData<Weapon[]>().then(
      (result) => {
        this.weapons = result;
        this.hasLoadedWeapons = true;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Weapons could not be retrieved!",
        });
        this.hasLoadedWeapons = false;
      }
    );
  }

  refreshGroups() {
    this.resetGroup();
    this.groups = [];
    this.groupService.getData<WeaponGroup[]>().then(
      (result) => {
        this.groups = result;
        this.hasLoadedGroups = true;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Groups could not be retrieved!",
        });
        this.hasLoadedGroups = false;
      }
    );
  }

  refreshTypes() {
    this.resetType();
    this.types = [];
    this.typeService.getData<WeaponProfileModeType[]>().then(
      (result) => {
        this.types = result;
        this.hasLoadedTypes = true;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Weapon Profile Types could not be retrieved!",
        });
        this.hasLoadedTypes = false;
      }
    );
  }

  setWeapon(id: string) {
    this.resetWeapon();
    this.selectedWeapon = this.weapons.find((weapon) => {
      return weapon.id === id;
    });
  }

  setGroup(id: string) {
    this.resetGroup();
    this.selectedGroup = this.groups.find((weapon) => {
      return weapon.id === id;
    });
  }

  setType(id: string) {
    this.resetType();
    this.selectedType = this.types.find((weapon) => {
      return weapon.id === id;
    });
  }

  setWeaponModal(id?: string, title?: string, body?: string) {
    this.groupService.getData<WeaponGroup[]>().then(
      (result) => {
        this.modalGroups = result;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Groups could not be retrieved!",
        });
      }
    );
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  setGroupModal(id?: string, title?: string, body?: string) {
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  setTypeModal(id?: string, title?: string, body?: string) {
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  resetWeapon() {
    this.selectedWeapon = {
      id: "",
      name: null,
      description: null,
      value: 0,
      created: "",
      modified: "",
      isActive: false,
      factions: null,
      weaponGroup: null,
      weaponProfiles: null,
    };
  }

  resetGroup() {
    this.selectedGroup = {
      id: "",
      name: null,
      description: null,
      created: "",
      modified: "",
      isActive: false,
      weaponProfiles: null,
    };
  }

  resetType() {
    this.selectedType = {
      id: "",
      name: null,
      description: null,
      created: "",
      modified: "",
      isActive: false,
    };
  }

  createWeaponForm(content: any, value: string) {
    this.resetWeapon();
    this.setWeaponModal("", "Create a new Weapon", "");
    this.modalService.open(content, { centered: true });
  }

  createWeapon() {
    this.selectedWeapon.description = this.selectedWeapon.name;
    this.weaponService
      .post<Weapon>(this.selectedWeapon, {
        weaponGroupId: this.selectedWeapon.weaponGroup.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Weapon was created successfully!",
          });
          this.refreshWeapons();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Weapon could not be created!",
          });
        }
      );
  }

  createGroupForm(content: any, value: string) {
    this.resetGroup();
    this.setGroupModal("", "Create a new Group", "");
    this.modalService.open(content, { centered: true });
  }

  createTypeForm(content: any, value: string) {
    this.resetType();
    this.setTypeModal("", "Create a new Type", "");
    this.modalService.open(content, { centered: true });
  }

  createGroup() {
    this.selectedGroup.description = this.selectedGroup.name;
    this.groupService.post<WeaponGroup>(this.selectedGroup).subscribe(
      (result) => {
        this.addAlert({
          type: "success",
          message: "Group was created successfully!",
        });
        this.refreshGroups();
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Group could not be created!",
        });
      }
    );
  }

  createType() {
    this.selectedType.description = this.selectedType.name;
    this.typeService.post<WeaponProfileModeType>(this.selectedType).subscribe(
      (result) => {
        this.addAlert({
          type: "success",
          message: "Type was created successfully!",
        });
        this.refreshTypes();
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Type could not be created!",
        });
      }
    );
  }

  deleteWeaponConfirm(content: any, value: string) {
    this.setWeapon(value);
    this.setWeaponModal(
      value,
      "Delete " + this.selectedWeapon.name,
      'Are you sure you want to delete Weapon: "' +
        this.selectedWeapon.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.deleteWeapon();
        }
      })
      .catch((res) => {});
  }

  deleteWeapon() {
    this.weaponService.delete<Weapon>({ id: this.selectedWeapon.id }).subscribe(
      (result) => {
        this.addAlert({
          type: "success",
          message:
            'Weapon: "' +
            this.selectedWeapon.name +
            '" was deleted successfully!',
        });
        this.weapons = this.weapons.filter(
          ({ id }) => id !== this.selectedWeapon.id
        );
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message:
            'Weapon: "' + this.selectedWeapon.name + '" could not be deleted!',
        });
      }
    );
  }

  toggleWeaponConfirm(content: any, value: string) {
    this.setWeapon(value);
    this.setWeaponModal(
      value,
      (this.selectedWeapon.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedWeapon.name,
      "Are you sure you want to " +
        (this.selectedWeapon.isActive ? "disable" : "enable") +
        ' Weapon: "' +
        this.selectedWeapon.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleWeapon();
        }
      })
      .catch((res) => {});
  }

  toggleWeapon() {
    this.weaponService
      .patch<Patch>(
        [
          {
            op: "replace",
            path: "/isActive",
            value: String(!this.selectedWeapon.isActive),
          },
        ],
        { id: this.selectedWeapon.id }
      )
      .subscribe(
        (result) => {
          this.selectedWeapon.isActive = !this.selectedWeapon.isActive;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Field: "' + this.selectedWeapon.name + '" could not be updated!',
          });
        }
      );
  }

  toggleGroupConfirm(content: any, value: string) {
    this.setGroup(value);
    this.setWeaponModal(
      value,
      (this.selectedGroup.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedGroup.name,
      "Are you sure you want to " +
        (this.selectedGroup.isActive ? "disable" : "enable") +
        ' Group: "' +
        this.selectedGroup.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleGroup();
        }
      })
      .catch((res) => {});
  }

  toggleGroup() {
    this.groupService
      .patch<Patch>(
        [
          {
            op: "replace",
            path: "/isActive",
            value: String(!this.selectedGroup.isActive),
          },
        ],
        { id: this.selectedGroup.id }
      )
      .subscribe(
        (result) => {
          this.selectedGroup.isActive = !this.selectedGroup.isActive;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Field: "' + this.selectedGroup.name + '" could not be updated!',
          });
        }
      );
  }

  toggleTypeConfirm(content: any, value: string) {
    this.setType(value);
    this.setWeaponModal(
      value,
      (this.selectedType.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedType.name,
      "Are you sure you want to " +
        (this.selectedType.isActive ? "disable" : "enable") +
        ' Type: "' +
        this.selectedType.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleType();
        }
      })
      .catch((res) => {});
  }

  toggleType() {
    this.typeService
      .patch<Patch>(
        [
          {
            op: "replace",
            path: "/isActive",
            value: String(!this.selectedType.isActive),
          },
        ],
        { id: this.selectedType.id }
      )
      .subscribe(
        (result) => {
          this.selectedType.isActive = !this.selectedType.isActive;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Field: "' + this.selectedType.name + '" could not be updated!',
          });
        }
      );
  }

  viewWeapon(value: any) {
    this.router.navigate(["admin/weapons/" + value]);
  }

  viewGroup(value: any) {
    this.router.navigate(["admin/weapons/groups/" + value]);
  }

  viewType(value: any) {
    this.router.navigate(["admin/weapons/types/" + value]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
