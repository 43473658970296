import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class ActionService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  get<Action>(ids): Promise<Action> {
    var url = this.baseUrl + "api/Actions/" + ids.id + this.getCacheBust;
    return this.http.get<Action>(url).toPromise();
  }

  getFull<Action>(ids): Promise<Action> {
    var url = this.baseUrl + "api/Actions/" + ids.id + "/Full" + this.getCacheBust;
    return this.http.get<Action>(url).toPromise();
  }

  put<Action>(item, ids): Observable<Action> {
    var url = this.baseUrl + "api/Actions/" + ids.id;
    return this.http.put<Action>(url, item);
  }

  putPriority<Action>(item, ids): Observable<Action> {
    var url = this.baseUrl + "api/Actions/" + ids.id + "/Target/" + ids.targetId;
    return this.http.put<Action>(url, item);
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/Actions/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<Action>(item, ids): Observable<Action> {
    var url = this.baseUrl + "api/Actions/" + ids.id + "/Phase/" + ids.phaseId;
    return this.http.post<Action>(url, item);
  }

  delete<Action>(ids): Observable<Action> {
    var url = this.baseUrl + "api/Actions/" + ids.id;
    return this.http.delete<Action>(url);
  }
}
