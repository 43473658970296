import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class WeaponService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getData<T>(): Promise<T> {
    var url = this.baseUrl + "api/Weapons/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  get<Weapon>(ids): Promise<Weapon> {
    var url = this.baseUrl + "api/Weapons/" + ids.id + this.getCacheBust;
    return this.http.get<Weapon>(url).toPromise();
  }

  getFull<Weapon>(ids): Promise<Weapon> {
    var url =
      this.baseUrl + "api/Weapons/" + ids.id + "/Full" + this.getCacheBust;
    return this.http.get<Weapon>(url).toPromise();
  }

  put<Weapon>(item, ids): Observable<Weapon> {
    var url = this.baseUrl + "api/Weapons/" + ids.id;
    return this.http.put<Weapon>(url, item);
  }

  putAddNewWeaponProfile<Weapon>(item, ids): Observable<Weapon> {
    var url = this.baseUrl + "api/Weapons/" + ids.id + "/WeaponProfile/New/WeaponGroup/" + ids.weaponGroupId;
    return this.http.put<Weapon>(url, item);
  }

  putAddExistingFaction<Weapon>(item, ids): Observable<Weapon> {
    var url = this.baseUrl + "api/Weapons/" + ids.id + "/Faction/" + ids.factionId + "/Add";
    return this.http.put<Weapon>(url, item);
  }

  putAddExistingWeaponProfile<Weapon>(item, ids): Observable<Weapon> {
    var url = this.baseUrl + "api/Weapons/" + ids.id + "/WeaponProfile/" + ids.weaponProfileId + "/Add";
    return this.http.put<Weapon>(url, item);
  }

  putRemoveExistingFaction<Weapon>(item, ids): Observable<Weapon> {
    var url = this.baseUrl + "api/Weapons/" + ids.id + "/Faction/" + ids.factionId + "/Remove";
    return this.http.put<Weapon>(url, item);
  }

  putRemoveExistingWeaponProfile<Weapon>(item, ids): Observable<Weapon> {
    var url = this.baseUrl + "api/Weapons/" + ids.id + "/WeaponProfile/" + ids.weaponProfileId + "/Remove";
    return this.http.put<Weapon>(url, item);
  }

  putUpdateWargear<Weapon>(item, ids): Observable<Weapon> {
    var url = this.baseUrl + "api/Weapons/" + ids.id + "/Wargear";
    return this.http.put<Weapon>(url, item);
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/Weapons/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<Weapon>(item, ids): Observable<Weapon> {
    var url = this.baseUrl + "api/Weapons/New/WeaponGroup/" + ids.weaponGroupId;
    return this.http.post<Weapon>(url, item);
  }

  delete<Weapon>(ids): Observable<Weapon> {
    var url = this.baseUrl + "api/Weapons/" + ids.id;
    return this.http.delete<Weapon>(url);
  }
}
