import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Faction } from "src/app/models/faction.model";
import { Patch } from "src/app/models/patch.model";
import { Weapon } from "src/app/models/weapon.model";
import { WeaponGroup } from "src/app/models/weapongroup.model";
import { WeaponProfile } from "src/app/models/weaponprofile.model";
import { FactionService } from "src/app/services/faction.service";
import { WeaponService } from "src/app/services/weapon.service";
import { WeaponGroupService } from "src/app/services/weapongroup.service";

@Component({
  selector: "app-weapon",
  templateUrl: "./weapon.component.html",
  preserveWhitespaces: true,
})
export class WeaponComponent {
  factions: Faction[] = [];
  profiles: WeaponProfile[] = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalTitle: string;
  modalFactions: Faction[] = [];
  modalProfiles: WeaponProfile[] = [];
  modalBody: string;
  modalId: string;

  selectedFaction: Faction;
  selectedProfile: WeaponProfile;

  weapon: Weapon;
  weaponName: string = "Weapon";

  alerts: Alert[] = [];

  hasLoadedWeapon: boolean = false;
  hasLoadedProfiles: boolean = false;
  hasLoadedFactions: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private weaponService: WeaponService,
    private factionService: FactionService,
    private typeService: WeaponGroupService,
    private modalService: NgbModal
  ) {
    this.getWeapon();
  }

  getWeapon() {
    this.weaponService
      .getFull<Weapon>({
        id: this.activatedRoute.snapshot.paramMap.get("weaponId"),
      })
      .then(
        (result) => {
          this.weapon = result;
          this.hasLoadedWeapon = true;
          this.factions = this.weapon.factions;
          this.hasLoadedFactions = true;
          this.profiles = this.weapon.weaponProfiles;
          this.hasLoadedProfiles = true;
          this.setName();
        },
        (error) => {
          this.hasLoadedWeapon = false;
          this.hasLoadedProfiles = false;
          this.addAlert({
            type: "danger",
            message: "Weapon could not be retrieved!",
          });
        }
      );
  }

  refreshFactions() {
    this.resetNewFaction();
    this.factions = [];
    this.weaponService.getFull<Weapon>({ id: this.weapon.id }).then(
      (result) => {
        this.factions = result.factions;
        this.hasLoadedFactions = true;
      },
      (error) => {
        this.hasLoadedFactions = false;
        this.addAlert({
          type: "danger",
          message: "Factions could not be retrieved!",
        });
      }
    );
  }

  refreshProfiles() {
    this.resetNewProfile();
    this.profiles = [];
    this.weaponService.getFull<Weapon>({ id: this.weapon.id }).then(
      (result) => {
        this.profiles = result.weaponProfiles;
        this.hasLoadedProfiles = true;
      },
      (error) => {
        this.hasLoadedProfiles = false;
        this.addAlert({
          type: "danger",
          message: "Profiles could not be retrieved!",
        });
      }
    );
  }

  setFaction(id: string) {
    this.resetNewFaction();
    this.selectedFaction = this.factions.find((row) => {
      return row.id === id;
    });
  }

  setProfile(id: string) {
    this.resetNewProfile();
    this.selectedProfile = this.profiles.find((row) => {
      return row.id === id;
    });
  }

  setName() {
    this.weaponName = this.weapon.name;
  }

  setFactionModal(id?: string, title?: string, body?: string) {
    this.modalFactions = [];
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  setProfileModal(id?: string, title?: string, body?: string) {
    this.modalProfiles = [];
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  setExistingFactionModal(id?: string, title?: string, body?: string) {
    this.factionService.getData<Faction[]>().then(
      (result) => {
        this.modalFactions = result.filter(
          (x) => !this.factions.find((y) => y.id === x.id)
        );
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Factions could not be retrieved!",
        });
      }
    );
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  setExistingProfileModal(id?: string, title?: string, body?: string) {
    this.typeService
      .getFull<WeaponGroup>({ id: this.weapon.weaponGroup.id })
      .then(
        (result) => {
          this.modalProfiles = result.weaponProfiles.filter(
            (x) => !this.profiles.find((y) => y.id === x.id)
          );
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Profiles could not be retrieved!",
          });
        }
      );
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  resetNewFaction() {
    this.selectedFaction = {
      id: "",
      name: "",
      description: "",
      colour: "",
      imagePath: "",
      created: "",
      modified: "",
      isActive: true,
      branches: null,
      factionFirstNames: null,
      factionLastNames: null,
      gameMode: null,
      models: null,
      wargear: null,
      weapons: null,
    };
  }

  resetNewProfile() {
    this.selectedProfile = {
      id: "",
      name: this.weapon.name,
      description: this.weapon.name,
      created: "",
      modified: "",
      isActive: true,
      weaponGroup: null,
      weaponProfileModes: null,
    };
  }

  resetNewWargear() {
    this.selectedProfile = {
      id: "",
      name: this.weapon.name,
      description: this.weapon.name,
      created: "",
      modified: "",
      isActive: true,
      weaponGroup: null,
      weaponProfileModes: null,
    };
  }

  resetExistingFaction() {
    this.selectedFaction = null;
  }

  resetExistingProfile() {
    this.selectedProfile = null;
  }

  patch(patch: Patch, field: string) {
    this.weaponService.patch<Patch>([patch], { id: this.weapon.id }).subscribe(
      (result) => {
        this.addAlert({
          type: "success",
          message: 'Field: "' + field + '" was updated successfully!',
        });
        this.setName();
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: 'Field: "' + field + '" could not be updated!',
        });
      }
    );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.weapon.name },
      "Name"
    );
  }

  editValue() {
    this.patch(
      { op: "replace", path: "/value", value: this.weapon.value },
      "Value"
    );
  }

  createProfileForm(content: any) {
    this.resetNewProfile();
    this.setProfileModal("", "Create a new Profile", "");
    this.modalService.open(content, { centered: true });
  }

  createProfile() {
    this.weaponService
      .putAddNewWeaponProfile<Weapon>(this.selectedProfile, {
        id: this.weapon.id,
        weaponGroupId: this.weapon.weaponGroup.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Profile was created successfully!",
          });
          this.refreshProfiles();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Profile could not be created!",
          });
        }
      );
  }

  addFactionForm(content: any) {
    this.resetExistingFaction();
    this.setExistingFactionModal("", "Add a existing Faction", "");
    this.modalService.open(content, { centered: true });
  }

  addProfileForm(content: any) {
    this.resetExistingProfile();
    this.setExistingProfileModal("", "Add a existing Profile", "");
    this.modalService.open(content, { centered: true });
  }

  addFaction() {
    this.weaponService
      .putAddExistingFaction<Weapon>(this.selectedFaction, {
        id: this.weapon.id,
        factionId: this.selectedFaction.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Faction was added successfully!",
          });
          this.refreshFactions();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Faction could not be added!",
          });
        }
      );
  }

  addProfile() {
    this.weaponService
      .putAddExistingWeaponProfile<Weapon>(this.selectedProfile, {
        id: this.weapon.id,
        weaponProfileId: this.selectedProfile.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Profile was added successfully!",
          });
          this.refreshProfiles();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Profile could not be added!",
          });
        }
      );
  }

  deleteFactionConfirm(content: any, value: string) {
    this.setFaction(value);
    this.setFactionModal(
      value,
      "Remove " + this.selectedFaction.name,
      'Are you sure you want to remove Faction: "' +
        this.selectedFaction.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.deleteFaction();
        }
      })
      .catch((res) => {});
  }

  deleteProfileConfirm(content: any, value: string) {
    this.setProfile(value);
    this.setProfileModal(
      value,
      "Remove " + this.selectedProfile.name,
      'Are you sure you want to remove Profile: "' +
        this.selectedProfile.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.deleteProfile();
        }
      })
      .catch((res) => {});
  }

  deleteFaction() {
    this.weaponService
      .putRemoveExistingFaction<Weapon>(this.selectedFaction, {
        id: this.weapon.id,
        factionId: this.selectedFaction.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message:
              'Faction: "' +
              this.selectedFaction.name +
              '" was removed successfully!',
          });
          this.factions = this.factions.filter(
            ({ id }) => id !== this.selectedFaction.id
          );
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Faction: "' +
              this.selectedFaction.name +
              '" could not be removed!',
          });
        }
      );
  }

  deleteProfile() {
    this.weaponService
      .putRemoveExistingWeaponProfile<Weapon>(this.selectedProfile, {
        id: this.weapon.id,
        weaponProfileId: this.selectedProfile.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message:
              'Profile: "' +
              this.selectedProfile.name +
              '" was removed successfully!',
          });
          this.profiles = this.profiles.filter(
            ({ id }) => id !== this.selectedProfile.id
          );
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Profile: "' +
              this.selectedProfile.name +
              '" could not be removed!',
          });
        }
      );
  }

  viewFaction(value: any) {
    this.router.navigate(["admin/factions/" + value]);
  }

  viewProfile(value: any) {
    this.router.navigate([
      "admin/weapons/" + this.weapon.id + "/profiles/" + value,
    ]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
