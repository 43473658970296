import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Match } from "src/app/models/match.model";
import { MatchFact } from "src/app/models/matchfact.model";
import { ModelFact } from "src/app/models/modelfact.model";
import { Roster } from "src/app/models/roster.model";
import { Simulation } from "src/app/models/simulation.model";
import { WargearFact } from "src/app/models/wargearfact.model";
import { SimulationService } from "src/app/services/simulation.service";

@Component({
  selector: "app-results",
  templateUrl: "./results.component.html",
  preserveWhitespaces: true,
})
export class ResultsComponent {
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  simulation: Simulation;
  simulationId: string = "";
  simulationName: string = "Simulation";

  blue: Roster;
  blueKills: number = 0;
  blueDeaths: number = 0;
  blueVictoryPoints: number = 1;

  matches: Match[];

  matchFacts: MatchFact[];
  modelFacts: ModelFact[];
  wargearFacts: WargearFact[];

  red: Roster;
  redKills: number = 0;
  redDeaths: number = 0;
  redVictoryPoints: number = 1;

  alerts: Alert[] = [];

  hasLoadedBlue: boolean;
  hasLoadedRed: boolean;
  hasLoadedSimulation: boolean;

  hasSelectedMatches: boolean;
  hasSelectedModels: boolean;
  hasSelectedWargear: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private simulationService: SimulationService
  ) {
    this.getSimulation();
  }

  getSimulation() {
    this.simulationService
      .getFull<Simulation>({
        id: this.activatedRoute.snapshot.paramMap.get("simulationId"),
      })
      .then(
        (result) => {
          this.simulation = result;
          this.hasLoadedSimulation = true;
          this.blue = this.simulation.blueRoster;
          this.hasLoadedBlue = true;
          this.red = this.simulation.redRoster;
          this.hasLoadedRed = true;
          this.matches = this.simulation.matches;
          this.matchFacts =
            this.simulation.dimensions[
              this.simulation.dimensions.length - 1
            ].matchFacts;
          this.modelFacts =
            this.simulation.dimensions[
              this.simulation.dimensions.length - 1
            ].modelFacts;
          this.wargearFacts =
            this.simulation.dimensions[
              this.simulation.dimensions.length - 1
            ].wargearFacts;
          this.setId();
          this.setName();
          this.setValues();
          this.setSelectedLists();
        },
        (error) => {
          this.hasLoadedSimulation = false;
          this.hasLoadedBlue = false;
          this.hasLoadedRed = false;
          this.addAlert({
            type: "danger",
            message: "Simulation could not be retrieved!",
          });
        }
      );
  }

  setId() {
    this.simulationId = this.simulation.id;
  }

  setName() {
    this.simulationName = this.simulation.name;
  }

  setValues() {
    this.blueKills = this.matchFacts
      .filter((x) => x.team === "B")
      .reduce((accumulator, current) => accumulator + current.kills, 0);
    this.blueDeaths = this.matchFacts
      .filter((x) => x.team === "B")
      .reduce((accumulator, current) => accumulator + current.deaths, 0);
    this.blueVictoryPoints = this.matchFacts
      .filter((x) => x.team === "B")
      .reduce((accumulator, current) => accumulator + current.victoryPoints, 0);

    this.redKills = this.matchFacts
      .filter((x) => x.team === "R")
      .reduce((accumulator, current) => accumulator + current.kills, 0);
    this.redDeaths = this.matchFacts
      .filter((x) => x.team === "R")
      .reduce((accumulator, current) => accumulator + current.deaths, 0);
    this.redVictoryPoints = this.matchFacts
      .filter((x) => x.team === "R")
      .reduce((accumulator, current) => accumulator + current.victoryPoints, 0);
  }

  setSelectedLists() {
    this.hasSelectedMatches = false;
    this.hasSelectedModels = false;
    this.hasSelectedWargear = false;
  }

  viewModels() {
    this.setSelectedLists();
    this.hasSelectedModels = true;
  }

  viewMatches() {
    this.setSelectedLists();
    this.hasSelectedMatches = true;
  }

  viewWargear() {
    this.setSelectedLists();
    this.hasSelectedWargear = true;
  }

  viewMatchLog(value: any) {
    this.router.navigate([
      "simulation/history/" + this.simulation.id + "/results/" + value,
    ]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
