import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class ModelService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getData<T>(): Promise<T> {
    var url = this.baseUrl + "api/Models/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  get<Model>(ids): Promise<Model> {
    var url = this.baseUrl + "api/Models/" + ids.id + this.getCacheBust;
    return this.http.get<Model>(url).toPromise();
  }

  getFull<Model>(ids): Promise<Model> {
    var url = this.baseUrl + "api/Models/" + ids.id + "/Full" + this.getCacheBust;
    return this.http.get<Model>(url).toPromise();
  }

  put<Model>(item, ids): Observable<Model> {
    var url = this.baseUrl + "api/Models/" + ids.id;
    return this.http.put<Model>(url, item);
  }

  putAddNewWargear<Model>(item, ids): Observable<Model> {
    var url = this.baseUrl + "api/Models/" + ids.id + "/Wargear/New/Faction/" + ids.factionId;
    return this.http.put<Model>(url, item);
  }

  putAddExistingWargear<Model>(item, ids): Observable<Model> {
    var url = this.baseUrl + "api/Models/" + ids.id + "/Wargear/" + ids.wargearId + "/Add";
    return this.http.put<Model>(url, item);
  }

  putRemoveExistingWargear<Model>(item, ids): Observable<Model> {
    var url = this.baseUrl + "api/Models/" + ids.id + "/Wargear/" + ids.wargearId + "/Remove";
    return this.http.put<Model>(url, item);
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/Models/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<Model>(item, ids): Observable<Model> {
    var url = this.baseUrl + "api/Models/New/Faction/" + ids.factionId;
    return this.http.post<Model>(url, item);
  }

  delete<Model>(ids): Observable<Model> {
    var url = this.baseUrl + "api/Models/" + ids.id;
    return this.http.delete<Model>(url);
  }

  upload<Upload>(item): Observable<Upload> {
    var url = this.baseUrl + "api/Upload/";
    return this.http.post<Upload>(url, item);
  }
}
