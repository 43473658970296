import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Branch } from "src/app/models/branch.model";
import { Faction } from "src/app/models/faction.model";
import { GameMode } from "src/app/models/gamemode.model";
import { Roster } from "src/app/models/roster.model";
import { RosterType } from "src/app/models/rostertype.model";
import { User } from "src/app/models/user.model";
import { GameModeService } from "src/app/services/gamemode.service";
import { RosterService } from "src/app/services/roster.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-rosters",
  templateUrl: "./rosters.component.html",
  preserveWhitespaces: true,
})
export class RostersComponent {
  rosters = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalBranches: Branch[];
  modalFactions: Faction[];
  modalGameModes: GameMode[];
  modalTypes: RosterType[];
  modalTitle: string;
  modalBody: string;
  modalId: string;

  selectedRoster: Roster;

  alerts: Alert[] = [];

  hasLoadedRosters: boolean = false;

  constructor(
    private router: Router,
    private gameModeService: GameModeService,
    private rosterService: RosterService,
    private userService: UserService,
    private modalService: NgbModal
  ) {
    this.refreshRoster();
  }

  refreshRoster() {
    this.rosters = [];
    this.userService.getForRostersFull<User>().then(
      (result) => {
        this.rosters = result.rosters;
        this.resetRoster();
        this.hasLoadedRosters = true;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Rosters could not be retrieved!",
        });
      }
    );
  }

  setRoster(id: string) {
    this.resetRoster();
    this.selectedRoster = this.rosters.find((roster) => {
      return roster.id === id;
    });
  }

  setRosterModal(id?: string, title?: string, body?: string) {
    this.gameModeService.getData<GameMode[]>().then(
      (result) => {
        this.modalGameModes = result;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Game Modes could not be retrieved!",
        });
      }
    );
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  getBranches() {
    this.selectedRoster.branch = null;
    this.modalBranches = this.selectedRoster.faction.branches;
  }

  getFactions() {
    this.selectedRoster.faction = null;
    this.modalFactions = this.selectedRoster.gameMode.factions;
  }

  getTypes() {
    this.selectedRoster.rosterType = null;
    this.modalTypes = this.selectedRoster.gameMode.rosterTypes;
  }

  resetRoster() {
    this.selectedRoster = {
      id: "",
      name: null,
      description: null,
      value: 0,
      created: "",
      modified: "",
      isActive: true,
      branch: null,
      faction: null,
      gameMode: null,
      rosterModels: null,
      rosterType: null,
    };
  }

  defaultRosterName() {
    this.selectedRoster.name =
      this.selectedRoster.branch.name +
      " " +
      (this.rosters.filter(
        ({ branch }) => branch.name === this.selectedRoster.branch.name
      ).length +
        1) +
      " (" +
      this.selectedRoster.rosterType.name +
      ")";
  }

  createRosterForm(content: any, value: string) {
    this.resetRoster();
    this.setRosterModal("", "Create a new Roster", "");
    this.modalService.open(content, { centered: true });
  }

  createRoster() {
    this.selectedRoster.description = this.selectedRoster.name;
    this.rosterService
      .post<Roster>(this.selectedRoster, {
        branchId: this.selectedRoster.branch.id,
        factionId: this.selectedRoster.faction.id,
        gameModeId: this.selectedRoster.gameMode.id,
        rosterTypeId: this.selectedRoster.rosterType.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Roster was created successfully!",
          });
          this.refreshRoster();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Roster could not be created!",
          });
        }
      );
  }

  deleteRosterConfirm(content: any, value: string) {
    this.setRoster(value);
    this.setRosterModal(
      value,
      "Delete " + this.selectedRoster.name,
      'Are you sure you want to delete Roster: "' +
        this.selectedRoster.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.deleteRoster();
        }
      })
      .catch((res) => {});
  }

  deleteRoster() {
    this.rosterService.delete<Roster>({ id: this.selectedRoster.id }).subscribe(
      (result) => {
        this.addAlert({
          type: "success",
          message:
            'Roster: "' +
            this.selectedRoster.name +
            '" was deleted successfully!',
        });
        this.rosters = this.rosters.filter(
          ({ id }) => id !== this.selectedRoster.id
        );
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message:
            'Roster: "' + this.selectedRoster.name + '" could not be deleted!',
        });
      }
    );
  }

  viewRoster(value: any) {
    this.router.navigate(["account/rosters/" + value]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
