import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { GameMode } from "src/app/models/gamemode.model";
import { Patch } from "src/app/models/patch.model";
import { Phase } from "src/app/models/phase.model";
import { Upload } from "src/app/models/upload.model";
import { GameModeService } from "src/app/services/gamemode.service";
import { PhaseService } from "src/app/services/phase.service";

@Component({
  selector: "app-gamemode",
  templateUrl: "./gamemode.component.html",
  preserveWhitespaces: true,
})
export class GameModeComponent {
  phases = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalTitle: string;
  modalBody: string;
  modalId: string;

  selectedPhase: Phase;

  gameMode: GameMode;
  gameModeName: string = "Game Mode";
  gameModeImagePath: string;

  prevPhase: Phase;

  alerts: Alert[] = [];

  filePath: string;

  hasLoadedGameMode: boolean = false;
  hasLoadedPhases: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gameModeService: GameModeService,
    private phaseService: PhaseService,
    private modalService: NgbModal
  ) {
    this.getGameMode();
  }

  getGameMode() {
    this.gameModeService
      .getFull<GameMode>({
        id: this.activatedRoute.snapshot.paramMap.get("gameModeId"),
      })
      .then(
        (result) => {
          this.gameMode = result;
          this.hasLoadedGameMode = true;
          this.phases = this.gameMode.phases;
          this.linkPhases();
          this.hasLoadedPhases = true;
          this.setName();
          this.setPath();
        },
        (error) => {
          this.hasLoadedGameMode = false;
          this.hasLoadedPhases = false;
          this.addAlert({
            type: "danger",
            message: "Game Mode could not be retrieved!",
          });
        }
      );
  }

  refresh() {
    this.resetPhase();
    this.phases = [];
    this.gameModeService.getFull<GameMode>({ id: this.gameMode.id }).then(
      (result) => {
        this.phases = result.phases;
        this.linkPhases();
        this.hasLoadedPhases = true;
      },
      (error) => {
        this.hasLoadedPhases = false;
        this.addAlert({
          type: "danger",
          message: "Phases could not be retrieved!",
        });
      }
    );
  }

  linkPhases() {
    for (let i = 0; i < this.phases.length; i++) {
      this.phases[i].nextPhase =
        i < this.phases.length ? this.phases[i + 1] : null;
      this.phases[i].previousPhase = i > 0 ? this.phases[i - 1] : null;
    }
  }

  setPhase(id: string) {
    this.resetPhase();
    this.selectedPhase = this.phases.find((phase) => {
      return phase.id === id;
    });
  }

  resetPhase() {
    this.selectedPhase = {
      id: "",
      name: null,
      description: null,
      priority: 0,
      created: "",
      modified: "",
      isActive: false,
      previousPhase: null,
      nextPhase: null,
      gameMode: null,
      actions: null,
    };
  }

  setName() {
    this.gameModeName = this.gameMode.name;
  }

  setPath() {
    this.gameModeImagePath = this.gameMode.imagePath
      ? this.gameModeService.baseUrl + this.gameMode.imagePath
      : null;
  }

  setPhaseModal(id?: string, title?: string, body?: string) {
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  patch(patch: Patch, field: string) {
    this.gameModeService
      .patch<Patch>([patch], { id: this.gameMode.id })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: 'Field: "' + field + '" was updated successfully!',
          });
          this.setName();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: 'Field: "' + field + '" could not be updated!',
          });
        }
      );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.gameMode.name },
      "Name"
    );
  }

  uploadFile(files) {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    this.filePath = fileToUpload.name;
    const formData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);

    this.gameModeService.upload<Upload>(formData).subscribe((result) => {
      this.gameModeImagePath = this.gameModeService.baseUrl + result.dbPath;
      this.patch(
        { op: "replace", path: "/imagePath", value: result.dbPath },
        "Image"
      );
    });
  }

  createForm(content: any) {
    this.resetPhase();
    this.setPhaseModal("", "Create a new Phase", "");
    this.modalService.open(content, { centered: true });
  }

  create() {
    this.selectedPhase.description = this.selectedPhase.name;
    this.prevPhase = this.phases
      .reverse()
      .filter((x) => x.nextPhase == null)[0];
    this.phaseService
      .post<Phase>(this.selectedPhase, {
        gameModeId: this.gameMode.id,
        id: this.prevPhase ? this.prevPhase.id : "New",
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Phase was created successfully!",
          });
          this.refresh();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Phase could not be created!",
          });
        }
      );
  }

  togglePhaseConfirm(content: any, value: string) {
    this.setPhase(value);
    this.setPhaseModal(
      value,
      (this.selectedPhase.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedPhase.name,
      "Are you sure you want to " +
        (this.selectedPhase.isActive ? "disable" : "enable") +
        ' Phase: "' +
        this.selectedPhase.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.togglePhase();
        }
      })
      .catch((res) => {});
  }

  togglePhase() {
    this.phaseService
      .patch<Patch>(
        [
          {
            op: "replace",
            path: "/isActive",
            value: String(!this.selectedPhase.isActive),
          },
        ],
        { id: this.selectedPhase.id }
      )
      .subscribe(
        (result) => {
          this.selectedPhase.isActive = !this.selectedPhase.isActive;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Field: "' + this.selectedPhase.name + '" could not be updated!',
          });
        }
      );
  }

  prev(phase: Phase) {
    this.update(
      {
        id: phase.id,
        name: phase.name,
        description: phase.description,
        priority: phase.priority,
        isActive: phase.isActive,
      },
      this.gameMode.id,
      phase.previousPhase.id
    );
  }

  next(phase: Phase) {
    this.update(
      {
        id: phase.id,
        name: phase.name,
        description: phase.description,
        priority: phase.priority,
        isActive: phase.isActive,
      },
      this.gameMode.id,
      phase.nextPhase.id
    );
  }

  update(phase: any, gameModeId: string, targetId: string) {
    this.phaseService
      .putPriority<Phase>(phase, {
        gameModeId: gameModeId,
        targetId: targetId,
        id: phase.id,
      })
      .subscribe(
        (result) => {
          this.refresh();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Priority could not be updated! Error: " + error,
          });
        }
      );
  }

  view(value: any) {
    this.router.navigate([
      "admin/gamemodes/" + this.gameMode.id + "/phases/" + value,
    ]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
