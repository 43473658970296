import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ErrorRoutingModule } from "./error-routing.module";
import { ErrorComponent } from "./error/error.component";

@NgModule({
  declarations: [ErrorComponent],
  imports: [FormsModule, ErrorRoutingModule],
  bootstrap: [],
})
export class ErrorModule {
  title = "Error | UBWHS";

  constructor() {}
}
