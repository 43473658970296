import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/auth/auth-guard.service";
import { BaseComponent } from "./views/layout/base/base.component";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./views/pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    component: BaseComponent,
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("./views/pages/home/home.module").then((m) => m.HomeModule),
        canActivate: [AuthGuard],
      },
      {
        path: "admin",
        loadChildren: () =>
          import("./views/pages/admin/admin.module").then((m) => m.AdminModule),
        canActivate: [AuthGuard],
        data: { roles: ["Administrator"] },
      },
      {
        path: "simulation",
        loadChildren: () =>
          import("./views/pages/simulation/simulation.module").then(
            (m) => m.SimulationModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "heuristics",
        loadChildren: () =>
          import("./views/pages/heuristics/heuristics.module").then(
            (m) => m.HeuristicsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "debug",
        loadChildren: () =>
          import("./views/pages/debug/debug.module").then((m) => m.DebugModule),
        canActivate: [AuthGuard],
        data: { roles: ["Administrator"] },
      },
      {
        path: "account",
        loadChildren: () =>
          import("./views/pages/account/account.module").then(
            (m) => m.AccountModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["User"] },
      },
      { path: "", redirectTo: "home", pathMatch: "full" },
    ],
  },
  {
    path: "error",
    loadChildren: () =>
      import("./views/pages/error/error.module").then((m) => m.ErrorModule),
  },
  { path: "**", redirectTo: "error/404" },
];

const extraOptions: ExtraOptions = {
  scrollPositionRestoration: "top",
  initialNavigation: "disabled",
};

@NgModule({
  imports: [RouterModule.forRoot(routes, extraOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
