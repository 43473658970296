import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexNonAxisChartSeries,
  ApexTheme,
  ApexXAxis,
  ChartComponent,
} from "ng-apexcharts";
import { interval, Subscription } from "rxjs";
import { Alert } from "src/app/models/alert.model";
import { Match } from "src/app/models/match.model";
import { MatchFact } from "src/app/models/matchfact.model";
import { Patch } from "src/app/models/patch.model";
import { Roster } from "src/app/models/roster.model";
import { Simulation } from "src/app/models/simulation.model";
import { MatchService } from "src/app/services/match.service";
import { SimulationService } from "src/app/services/simulation.service";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  nonAxisSeries: ApexNonAxisChartSeries;
  colors: string[];
  chart: ApexChart;
  xaxis: ApexXAxis;
  theme: ApexTheme;
  labels: string[];
};

@Component({
  selector: "app-process",
  templateUrl: "./process.component.html",
  preserveWhitespaces: true,
})
export class ProcessComponent {
  @ViewChild("blueChart") blueChart: ChartComponent;
  @ViewChild("redChart") redChart: ChartComponent;
  @ViewChild("vpChart") vpChart: ChartComponent;

  public blueTeamChart: Partial<ChartOptions>;
  public redTeamChart: Partial<ChartOptions>;
  public victoryPointsChart: Partial<ChartOptions>;

  subscription: Subscription;

  simulation: Simulation;
  simulationName: string = "Simulation";

  selectedMatch: Match;

  blue: Roster;
  blueKills: number = 0;
  blueDeaths: number = 0;
  blueVictoryPoints: number = 1;

  matchFacts: MatchFact[];

  matchNumber: number = 0;

  red: Roster;
  redKills: number = 0;
  redDeaths: number = 0;
  redVictoryPoints: number = 1;

  alerts: Alert[] = [];

  hasLoadedBlue: boolean;
  hasLoadedRed: boolean;
  hasLoadedSimulation: boolean;
  hasLoadedChartOptions: boolean;

  isPaused: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private matchService: MatchService,
    private simulationService: SimulationService
  ) {
    this.resetMatch();
    this.getSimulation();
  }

  getSimulation() {
    this.simulationService
      .getFull<Simulation>({
        id: this.activatedRoute.snapshot.paramMap.get("simulationId"),
      })
      .then(
        (result) => {
          this.simulation = result;
          this.hasLoadedSimulation = true;
          this.blue = this.simulation.blueRoster;
          this.hasLoadedBlue = true;
          this.red = this.simulation.redRoster;
          this.hasLoadedRed = true;
          this.matchFacts =
            this.simulation.dimensions[
              this.simulation.dimensions.length - 1
            ].matchFacts;
          this.setName();
          this.isPaused = true;
          this.setChartOptions();
          this.setChartValues();
          this.hasLoadedChartOptions = true;
        },
        (error) => {
          this.hasLoadedSimulation = false;
          this.hasLoadedBlue = false;
          this.hasLoadedRed = false;
          this.hasLoadedChartOptions = false;
          this.addAlert({
            type: "danger",
            message: "Simulation could not be retrieved!",
          });
        }
      );
  }

  resetMatch() {
    this.selectedMatch = {
      id: "",
      name: "",
      description: "",
      created: "",
      modified: "",
      isActive: true,
      matchLogs: null,
      simulation: null,
    };
  }

  setName() {
    this.simulationName = this.simulation.name;
  }

  setChartOptions() {
    this.blueTeamChart = {
      series: [{ name: "Blue Team", data: [this.blueKills, this.blueDeaths] }],
      chart: { height: 350, type: "bar" },
      xaxis: { categories: ["Kills", "Deaths"] },
      colors: ["#727cf5"],
      theme: { mode: "dark" },
    };
    this.redTeamChart = {
      series: [{ name: "Red Team", data: [this.redKills, this.redDeaths] }],
      chart: { height: 350, type: "bar" },
      xaxis: { categories: ["Kills", "Deaths"] },
      colors: ["#ff3366"],
      theme: { mode: "dark" },
    };
    this.victoryPointsChart = {
      nonAxisSeries: [this.blueVictoryPoints, this.redVictoryPoints],
      chart: { height: 350, type: "donut" },
      colors: ["#727cf5", "#ff3366"],
      labels: ["Blue Team", "Red Team"],
      theme: { mode: "dark" },
    };
  }

  setChartValues() {
    this.blueKills = this.matchFacts
      .filter((x) => x.team === "B")
      .reduce((accumulator, current) => accumulator + current.kills, 0);
    this.blueDeaths = this.matchFacts
      .filter((x) => x.team === "B")
      .reduce((accumulator, current) => accumulator + current.deaths, 0);
    this.blueVictoryPoints = this.matchFacts
      .filter((x) => x.team === "B")
      .reduce((accumulator, current) => accumulator + current.victoryPoints, 0);

    this.redKills = this.matchFacts
      .filter((x) => x.team === "R")
      .reduce((accumulator, current) => accumulator + current.kills, 0);
    this.redDeaths = this.matchFacts
      .filter((x) => x.team === "R")
      .reduce((accumulator, current) => accumulator + current.deaths, 0);
    this.redVictoryPoints = this.matchFacts
      .filter((x) => x.team === "R")
      .reduce((accumulator, current) => accumulator + current.victoryPoints, 0);

    this.blueTeamChart.series = [
      { name: "Blue Team", data: [this.blueKills, this.blueDeaths] },
    ];
    this.redTeamChart.series = [
      { name: "Red Team", data: [this.redKills, this.redDeaths] },
    ];
    this.victoryPointsChart.nonAxisSeries = [
      this.blueVictoryPoints,
      this.redVictoryPoints,
    ];
  }

  play() {
    this.isPaused = false;
    this.continue();
  }

  pause() {
    this.isPaused = true;
    this.subscription.unsubscribe();
  }

  complete() {
    this.setSimulationCompleted();
  }

  continue() {
    const source = interval(100);
    this.subscription = source.subscribe((val) => this.createMatch());
    this.matchNumber++;
  }

  viewResults() {
    this.router.navigate([
      "simulation/history/" + this.simulation.id + "/results",
    ]);
  }

  setSimulationCompleted() {
    this.simulationService
      .patch<Patch>([{ op: "replace", path: "/isCompleted", value: true }], {
        id: this.simulation.id,
      })
      .subscribe(
        (result) => {
          this.simulation.isCompleted = true;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Simulation could not be completed!",
          });
        }
      );
  }

  createMatch() {
    this.subscription.unsubscribe();

    if (this.router.url != "/simulation/history/" + this.simulation.id)
      this.pause();
    else if (!this.isPaused && this.matchFacts.length < 200)
      this.matchService
        .post<MatchFact[]>(this.selectedMatch, {
          simulationId: this.simulation.id,
          matchNumber: this.matchNumber,
        })
        .subscribe(
          (result) => {
            for (let key in result) {
              this.matchFacts.push(result[key]);
            }
            this.setChartValues();
            this.continue();
          },
          (error) => {
            this.pause();
            this.addAlert({
              type: "danger",
              message: "Match could not be created!",
            });
          }
        );

    if (this.matchFacts.length > 198) this.complete();
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
