import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Alert } from "src/app/models/alert.model";
import { Patch } from "src/app/models/patch.model";
import { WeaponProfileModeType } from "src/app/models/weaponprofilemodetype.model";
import { WeaponProfileModeTypeService } from "src/app/services/weaponprofilemodetype.service";

@Component({
  selector: "app-weaponprofilemodetype",
  templateUrl: "./weaponprofilemodetype.component.html",
  preserveWhitespaces: true,
})
export class WeaponProfileModeTypeComponent {
  type: WeaponProfileModeType;
  typeName: string = "Type";

  alerts: Alert[] = [];

  hasLoadedType: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private typeService: WeaponProfileModeTypeService
  ) {
    this.getWeapon();
  }

  getWeapon() {
    this.typeService
      .get<WeaponProfileModeType>({
        id: this.activatedRoute.snapshot.paramMap.get(
          "weaponProfileModeTypeId"
        ),
      })
      .then(
        (result) => {
          this.type = result;
          this.hasLoadedType = true;
          this.setName();
        },
        (error) => {
          this.hasLoadedType = false;
          this.addAlert({
            type: "danger",
            message: "Type could not be retrieved!",
          });
        }
      );
  }

  setName() {
    this.typeName = this.type.name;
  }

  patch(patch: Patch, field: string) {
    this.typeService.patch<Patch>([patch], { id: this.type.id }).subscribe(
      (result) => {
        this.addAlert({
          type: "success",
          message: 'Field: "' + field + '" was updated successfully!',
        });
        this.setName();
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: 'Field: "' + field + '" could not be updated!',
        });
      }
    );
  }

  editName() {
    this.patch({ op: "replace", path: "/name", value: this.type.name }, "Name");
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
