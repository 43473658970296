import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Alert } from "src/app/models/alert.model";
import { GameMode } from "src/app/models/gamemode.model";
import { Roster } from "src/app/models/roster.model";
import { RosterType } from "src/app/models/rostertype.model";
import { Simulation } from "src/app/models/simulation.model";
import { User } from "src/app/models/user.model";
import { GameModeService } from "src/app/services/gamemode.service";
import { RosterService } from "src/app/services/roster.service";
import { SimulationService } from "src/app/services/simulation.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-simulation",
  templateUrl: "./simulation.component.html",
  preserveWhitespaces: true,
})
export class SimulationComponent {
  gameModes: GameMode[] = [];

  rosters: Roster[] = [];

  types: RosterType[] = [];

  selectedSimulation: Simulation;

  alerts: Alert[] = [];

  hasLoadedGameModes: boolean = false;
  hasLoadedRosters: boolean = false;

  hasSelectedGameMode: boolean = false;
  hasSelectedRosters: boolean = false;
  hasSelectedType: boolean = false;

  constructor(
    private router: Router,
    private gameModeService: GameModeService,
    private rosterService: RosterService,
    private simulationService: SimulationService,
    private userService: UserService
  ) {
    this.resetSimulation();
    this.getGameModes();
  }

  getGameModes() {
    this.gameModes = [];
    this.gameModeService.getData<GameMode[]>().then(
      (result) => {
        this.gameModes = result;
        this.hasLoadedGameModes = true;
      },
      (error) => {
        this.hasLoadedGameModes = false;
        this.addAlert({
          type: "danger",
          message: "Game Modes could not be retrieved!",
        });
      }
    );
  }

  getRosters() {
    this.rosters = [];
    this.userService.getForRostersFull<User>().then(
      (result) => {
        this.rosters = result.rosters.filter(
          ({ rosterType }) =>
            rosterType.id == this.selectedSimulation.rosterType.id
        );
        this.rosters = this.rosters.filter(
          ({ value }) =>
            value > 0 && value <= this.selectedSimulation.rosterType.value
        );
        this.hasLoadedRosters = true;
      },
      (error) => {
        this.hasLoadedRosters = false;
        this.addAlert({
          type: "danger",
          message: "Rosters could not be retrieved!",
        });
      }
    );
  }

  getTypes() {
    this.hasSelectedType = null;
    this.types = this.selectedSimulation.gameMode.rosterTypes;
  }

  resetSimulation() {
    this.selectedSimulation = {
      id: "",
      name: "",
      description: "",
      created: "",
      modified: "",
      isActive: true,
      isCompleted: false,
      blueRoster: null,
      matches: null,
      redRoster: null,
      gameMode: null,
      dimensions: null,
      rosterType: null,
      user: null,
    };
  }

  setStepOne() {
    this.resetStepTwo();

    this.hasSelectedGameMode = true;
    this.hasSelectedType = true;

    this.getRosters();
  }

  setStepTwo() {
    this.hasSelectedRosters = true;
  }

  setStepThree() {
    this.simulationService
      .post<Simulation>(this.selectedSimulation, {
        blueId: this.selectedSimulation.blueRoster.id,
        redId: this.selectedSimulation.redRoster.id,
        gameModeId: this.selectedSimulation.gameMode.id,
        rosterTypeId: this.selectedSimulation.rosterType.id,
      })
      .subscribe(
        (result) => {
          this.router.navigate(["simulation/history/" + result.id]);
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Simulation could not be created!",
          });
        }
      );
  }

  resetStepOne() {
    this.resetStepTwo();

    this.hasLoadedRosters = false;

    this.hasSelectedGameMode = false;
    this.hasSelectedType = false;

    this.selectedSimulation.gameMode = null;
    this.selectedSimulation.rosterType = null;
  }

  resetStepTwo() {
    this.hasSelectedRosters = false;

    this.selectedSimulation.blueRoster = null;
    this.selectedSimulation.redRoster = null;
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
