import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NarikCustomValidatorsModule } from "@narik/custom-validators";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { RosterService } from "src/app/services/roster.service";
import { RosterModelService } from "src/app/services/rostermodel.service";
import { UserService } from "src/app/services/user.service";
import { AccountRoutingModule } from "./account-routing.module";
import { RosterComponent } from "./roster/roster.component";
import { RostersComponent } from "./rosters/rosters.component";

@NgModule({
  declarations: [RostersComponent, RosterComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AccountRoutingModule,
    ReactiveFormsModule,
    NarikCustomValidatorsModule,
    NgbModule,
    NgxDatatableModule,
    NgSelectModule,
  ],
  bootstrap: [],
  providers: [RosterService, RosterModelService, UserService],
})
export class AccountModule {}
