import { MenuItem } from "src/app/models/menu.model";

export const MENU: MenuItem[] = [
  {
    label: "Home",
    isTitle: true,
    role: "User",
  },
  {
    label: "Dashboard",
    icon: "home",
    link: "/home",
    role: "User",
  },
  {
    label: "Simulations",
    icon: "archive",
    link: "/home/simulations",
    role: "User",
  },
  {
    label: "Simulation",
    isTitle: true,
    role: "User",
  },
  {
    label: "Start",
    icon: "play-circle",
    link: "/simulation/run",
    role: "User",
  },
  {
    label: "History",
    icon: "clock",
    link: "/simulation/history",
    role: "User",
  },
  {
    label: "Heuristics",
    isTitle: true,
    role: "Administrator",
  },
  {
    label: "Queue",
    icon: "loader",
    link: "/heuristics/queue",
    role: "Administrator",
  },
  {
    label: "Admin",
    isTitle: true,
    role: "Administrator",
  },
  {
    label: "Factions",
    icon: "flag",
    link: "/admin/factions",
    role: "Administrator",
  },
  {
    label: "Game Modes",
    icon: "layers",
    link: "/admin/gamemodes",
    role: "Administrator",
  },
  {
    label: "Models",
    icon: "users",
    link: "/admin/models",
    role: "Administrator",
  },
  {
    label: "Roster Types",
    icon: "zap",
    link: "/admin/rostertypes",
    role: "Administrator",
  },
  {
    label: "Weapons",
    icon: "crosshair",
    link: "/admin/weapons",
    role: "Administrator",
  },
  {
    label: "Debug",
    isTitle: true,
    role: "Administrator",
  },
  {
    label: "Identity",
    icon: "user-plus",
    link: "/debug/identity",
    role: "Administrator",
  },
  {
    label: "My Account",
    isTitle: true,
    role: "User",
  },
  {
    label: "Rosters",
    icon: "shield",
    link: "/account/rosters",
    role: "User",
  },
];
