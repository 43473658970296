import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NarikCustomValidatorsModule } from "@narik/custom-validators";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ColorPickerModule } from "ngx-color-picker";
import { ActionService } from "src/app/services/action.service";
import { ActionOptionService } from "src/app/services/actionoption.service";
import { AttributeService } from "src/app/services/attribute.service";
import { BranchService } from "src/app/services/branch.service";
import { BranchFirstNameService } from "src/app/services/branchfirstname.service";
import { BranchLastNameService } from "src/app/services/branchlastname.service";
import { FactionService } from "src/app/services/faction.service";
import { FactionFirstNameService } from "src/app/services/factionfirstname.service";
import { FactionLastNameService } from "src/app/services/factionlastname.service";
import { GameModeService } from "src/app/services/gamemode.service";
import { ModelService } from "src/app/services/model.service";
import { PhaseService } from "src/app/services/phase.service";
import { RosterTypeService } from "src/app/services/rostertype.service";
import { WargearService } from "src/app/services/wargear.service";
import { WeaponService } from "src/app/services/weapon.service";
import { WeaponGroupService } from "src/app/services/weapongroup.service";
import { WeaponProfileService } from "src/app/services/weaponprofile.service";
import { WeaponProfileModeService } from "src/app/services/weaponprofilemode.service";
import { WeaponProfileModeTypeService } from "src/app/services/weaponprofilemodetype.service";
import { ActionComponent } from "./action/action.component";
import { ActionOptionComponent } from "./actionoption/actionoption.component";
import { AdminRoutingModule } from "./admin-routing.module";
import { BranchComponent } from "./branch/branch.component";
import { BranchFirstNameComponent } from "./branchfirstname/branchfirstname.component";
import { BranchLastNameComponent } from "./branchlastname/branchlastname.component";
import { FactionComponent } from "./faction/faction.component";
import { FactionFirstNameComponent } from "./factionfirstname/factionfirstname.component";
import { FactionLastNameComponent } from "./factionlastname/factionlastname.component";
import { FactionsComponent } from "./factions/factions.component";
import { GameModeComponent } from "./gamemode/gamemode.component";
import { GameModesComponent } from "./gamemodes/gamemodes.component";
import { ModelComponent } from "./model/model.component";
import { ModelsComponent } from "./models/models.component";
import { PhaseComponent } from "./phase/phase.component";
import { RosterTypeComponent } from "./rostertype/rostertype.component";
import { RosterTypesComponent } from "./rostertypes/rostertypes.component";
import { WargearComponent } from "./wargear/wargear.component";
import { WeaponComponent } from "./weapon/weapon.component";
import { WeaponGroupComponent } from "./weapongroup/weapongroup.component";
import { WeaponProfileComponent } from "./weaponprofile/weaponprofile.component";
import { WeaponProfileModeComponent } from "./weaponprofilemode/weaponprofilemode.component";
import { WeaponProfileModeTypeComponent } from "./weaponprofilemodetype/weaponprofilemodetype.component";
import { WeaponsComponent } from "./weapons/weapons.component";

@NgModule({
  declarations: [
    FactionsComponent,
    FactionComponent,
    FactionFirstNameComponent,
    FactionLastNameComponent,
    BranchComponent,
    BranchFirstNameComponent,
    BranchLastNameComponent,
    GameModesComponent,
    GameModeComponent,
    PhaseComponent,
    ActionComponent,
    ActionOptionComponent,
    ModelsComponent,
    ModelComponent,
    RosterTypesComponent,
    RosterTypeComponent,
    WargearComponent,
    WeaponsComponent,
    WeaponComponent,
    WeaponGroupComponent,
    WeaponProfileComponent,
    WeaponProfileModeComponent,
    WeaponProfileModeTypeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NarikCustomValidatorsModule,
    AdminRoutingModule,
    NgbModule,
    NgxDatatableModule,
    NgSelectModule,
    ColorPickerModule,
  ],
  bootstrap: [],
  providers: [
    BranchService,
    FactionService,
    GameModeService,
    PhaseService,
    ActionService,
    ActionOptionService,
    AttributeService,
    ModelService,
    RosterTypeService,
    WeaponService,
    WargearService,
    WeaponGroupService,
    WeaponProfileService,
    WeaponProfileModeService,
    WeaponProfileModeTypeService,
    BranchFirstNameService,
    BranchLastNameService,
    FactionFirstNameService,
    FactionLastNameService,
  ],
})
export class AdminModule {}
