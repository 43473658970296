import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Attribute } from "src/app/models/attribute.model";
import { Patch } from "src/app/models/patch.model";
import { Weapon } from "src/app/models/weapon.model";
import { WeaponProfile } from "src/app/models/weaponprofile.model";
import { WeaponProfileMode } from "src/app/models/weaponprofilemode.model";
import { WeaponProfileModeType } from "src/app/models/weaponprofilemodetype.model";
import { WeaponService } from "src/app/services/weapon.service";
import { WeaponProfileService } from "src/app/services/weaponprofile.service";
import { WeaponProfileModeService } from "src/app/services/weaponprofilemode.service";
import { WeaponProfileModeTypeService } from "src/app/services/weaponprofilemodetype.service";

@Component({
  selector: "app-weaponprofile",
  templateUrl: "./weaponprofile.component.html",
  preserveWhitespaces: true,
})
export class WeaponProfileComponent {
  modes: WeaponProfileMode[] = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalTitle: string;
  modalTypes: WeaponProfileModeType[] = [];
  modalBody: string;
  modalId: string;

  selectedMode: WeaponProfileMode;

  Attributes: any;

  weapon: Weapon;
  weaponId: string = "";
  weaponName: string = "Weapon";

  profile: WeaponProfile;
  profileName: string = "Profile";

  alerts: Alert[] = [];

  hasLoadedWeapon: boolean = false;
  hasLoadedProfile: boolean = false;
  hasLoadedModes: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private weaponService: WeaponService,
    private profileService: WeaponProfileService,
    private modeService: WeaponProfileModeService,
    private modeTypeService: WeaponProfileModeTypeService,
    private modalService: NgbModal
  ) {
    this.getWeapon();
    this.getProfile();
  }

  getWeapon() {
    this.weaponService
      .get<Weapon>({
        id: this.activatedRoute.snapshot.paramMap.get("weaponId"),
      })
      .then(
        (result) => {
          this.weapon = result;
          this.weaponId = this.weapon.id;
          this.weaponName = this.weapon.name;
          this.hasLoadedWeapon = true;
        },
        (error) => {
          this.hasLoadedWeapon = false;
          this.addAlert({
            type: "danger",
            message: "Weapon could not be retrieved!",
          });
        }
      );
  }

  getProfile() {
    this.profileService
      .getFull<WeaponProfile>({
        id: this.activatedRoute.snapshot.paramMap.get("weaponProfileId"),
      })
      .then(
        (result) => {
          this.profile = result;
          this.hasLoadedProfile = true;
          this.modes = this.profile.weaponProfileModes;
          this.hasLoadedModes = true;
          this.setName();
        },
        (error) => {
          this.hasLoadedProfile = false;
          this.hasLoadedModes = false;
          this.addAlert({
            type: "danger",
            message: "Profile could not be retrieved!",
          });
        }
      );
  }

  refreshModes() {
    this.resetMode();
    this.modes = [];
    this.profileService.getFull<WeaponProfile>({ id: this.profile.id }).then(
      (result) => {
        this.modes = result.weaponProfileModes;
        this.hasLoadedModes = true;
      },
      (error) => {
        this.hasLoadedModes = false;
        this.addAlert({
          type: "danger",
          message: "Modes could not be retrieved!",
        });
      }
    );
  }

  setMode(id: string) {
    this.resetMode();
    this.selectedMode = this.modes.find((row) => {
      return row.id === id;
    });
  }

  setName() {
    this.profileName = this.profile.name;
  }

  setModeModal(id?: string, title?: string, body?: string) {
    this.resetAttributes();
    this.modeTypeService.getData<WeaponProfileModeType[]>().then(
      (result) => {
        this.modalTypes = result;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Types could not be retrieved!",
        });
      }
    );
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  resetAttributes() {
    this.Attributes = {
      range: "",
      attacks: "",
      strength: "",
      armourPenetration: "",
      damage: "",
    };
  }

  resetMode() {
    this.selectedMode = {
      id: "",
      name: "Standard",
      description: "Standard",
      created: "",
      modified: "",
      isActive: true,
      attributes: null,
      weaponProfile: null,
      weaponProfileModeType: null,
    };
  }

  patch(patch: Patch, field: string) {
    this.profileService
      .patch<Patch>([patch], { id: this.profile.id })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: 'Field: "' + field + '" was updated successfully!',
          });
          this.setName();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: 'Field: "' + field + '" could not be updated!',
          });
        }
      );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.profile.name },
      "Name"
    );
  }

  createModeForm(content: any) {
    this.resetMode();
    this.setModeModal("", "Create a new Mode", "");
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  createMode() {
    this.selectedMode.attributes = this.setAttributes();
    this.modeService
      .post<WeaponProfileMode>(this.selectedMode, {
        weaponProfileId: this.profile.id,
        weaponProfileModeTypeId: this.selectedMode.weaponProfileModeType.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Mode was created successfully!",
          });
          this.refreshModes();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Mode could not be created!",
          });
        }
      );
  }

  setAttributes(): Attribute[] {
    return [
      this.newAttribute("Range", "R", this.Attributes.range, 1),
      this.newAttribute("Attacks", "A", this.Attributes.attacks, 2),
      this.newAttribute("Strength", "S", this.Attributes.strength, 3),
      this.newAttribute(
        "Armour Penetration",
        "AP",
        this.Attributes.armourPenetration,
        4
      ),
      this.newAttribute("Damage", "D", this.Attributes.damage, 5),
    ];
  }

  newAttribute(
    name: string,
    shortName: string,
    value: string,
    priority: number
  ): Attribute {
    var max = 0;
    var min = 0;
    var roll = 0;
    var sides = 0;
    var multiply = 1;
    var useParent = false;
    switch (true) {
      case value.indexOf("D") !== -1: {
        var values = value.split("D");
        min = Number(values[0]);
        max = min * Number(values[1]);
        roll = min;
        sides = Number(values[1]);
        break;
      }
      case value.indexOf("Melee") !== -1 || value.indexOf("User") !== -1: {
        useParent = true;
        break;
      }
      case value.indexOf("+") !== -1: {
        var values = value.split("+");
        min = Number(values[0]);
        roll = 1;
        sides = 6;
        break;
      }
      case value.indexOf("x") !== -1: {
        var values = value.split("x");
        multiply = Number(values[1]);
        useParent = true;
        break;
      }
      default: {
        max = value ? Number(value) : max;
        break;
      }
    }
    return {
      id: "",
      name: name,
      description: name,
      shortName: shortName,
      display: value,
      roll: roll,
      sides: sides,
      multiply: multiply,
      max: max,
      min: min,
      priority: priority,
      created: "",
      modified: "",
      isActive: true,
      useParent: useParent,
    };
  }

  deleteModeConfirm(content: any, value: string) {
    this.setMode(value);
    this.setModeModal(
      value,
      "Remove " + this.selectedMode.name,
      'Are you sure you want to remove Mode: "' + this.selectedMode.name + '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.deleteMode();
        }
      })
      .catch((res) => {});
  }

  deleteMode() {
    this.modeService
      .delete<WeaponProfileMode>({ id: this.selectedMode.id })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message:
              'Mode: "' +
              this.selectedMode.name +
              '" was removed successfully!',
          });
          this.modes = this.modes.filter(
            ({ id }) => id !== this.selectedMode.id
          );
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Mode: "' + this.selectedMode.name + '" could not be removed!',
          });
        }
      );
  }

  viewMode(value: any) {
    this.router.navigate([
      "admin/weapons/" +
        this.weapon.id +
        "/profiles/" +
        this.profile.id +
        "/modes/" +
        value,
    ]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
