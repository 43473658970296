import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";

@Injectable()
export class UserService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  get<User>(): Promise<User> {
    var url = this.baseUrl + "api/Users/" + this.getCacheBust;
    return this.http.get<User>(url).toPromise();
  }

  getForRosterFull<User>(ids): Promise<User> {
    var url = this.baseUrl + "api/Users/Rosters/" + ids.rosterId + "/Full/" + this.getCacheBust;
    return this.http.get<User>(url).toPromise();
  }

  getForRostersFull<User>(): Promise<User> {
    var url = this.baseUrl + "api/Users/Rosters/Full/" + this.getCacheBust;
    return this.http.get<User>(url).toPromise();
  }

  getForSimulationSimple<User>(ids): Promise<User> {
    var url = this.baseUrl + "api/Users/Simulation/Simple/Type/" + ids.typeId + this.getCacheBust;
    return this.http.get<User>(url).toPromise();
  }
}
