import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ActionComponent } from "./action/action.component";
import { ActionOptionComponent } from "./actionoption/actionoption.component";
import { BranchComponent } from "./branch/branch.component";
import { BranchFirstNameComponent } from "./branchfirstname/branchfirstname.component";
import { BranchLastNameComponent } from "./branchlastname/branchlastname.component";
import { FactionComponent } from "./faction/faction.component";
import { FactionFirstNameComponent } from "./factionfirstname/factionfirstname.component";
import { FactionLastNameComponent } from "./factionlastname/factionlastname.component";
import { FactionsComponent } from "./factions/factions.component";
import { GameModeComponent } from "./gamemode/gamemode.component";
import { GameModesComponent } from "./gamemodes/gamemodes.component";
import { ModelComponent } from "./model/model.component";
import { ModelsComponent } from "./models/models.component";
import { PhaseComponent } from "./phase/phase.component";
import { RosterTypeComponent } from "./rostertype/rostertype.component";
import { RosterTypesComponent } from "./rostertypes/rostertypes.component";
import { WargearComponent } from "./wargear/wargear.component";
import { WeaponComponent } from "./weapon/weapon.component";
import { WeaponGroupComponent } from "./weapongroup/weapongroup.component";
import { WeaponProfileComponent } from "./weaponprofile/weaponprofile.component";
import { WeaponProfileModeComponent } from "./weaponprofilemode/weaponprofilemode.component";
import { WeaponProfileModeTypeComponent } from "./weaponprofilemodetype/weaponprofilemodetype.component";
import { WeaponsComponent } from "./weapons/weapons.component";

const routes: Routes = [
  {
    path: "factions",
    component: FactionsComponent,
  },
  {
    path: "factions/:factionId",
    component: FactionComponent,
  },
  {
    path: "factions/:factionId/branches/:branchId",
    component: BranchComponent,
  },
  {
    path: "factions/:factionId/first/:firstNameId",
    component: FactionFirstNameComponent,
  },
  {
    path: "factions/:factionId/last/:lastNameId",
    component: FactionLastNameComponent,
  },
  {
    path: "factions/:factionId/branches/:branchId/first/:firstNameId",
    component: BranchFirstNameComponent,
  },
  {
    path: "factions/:factionId/branches/:branchId/last/:lastNameId",
    component: BranchLastNameComponent,
  },
  {
    path: "gamemodes",
    component: GameModesComponent,
  },
  {
    path: "gamemodes/:gameModeId",
    component: GameModeComponent,
  },
  {
    path: "gamemodes/:gameModeId/phases/:phaseId",
    component: PhaseComponent,
  },
  {
    path: "gamemodes/:gameModeId/phases/:phaseId/actions/:actionId",
    component: ActionComponent,
  },
  {
    path: "gamemodes/:gameModeId/phases/:phaseId/actions/:actionId/actionoptions/:actionOptionId",
    component: ActionOptionComponent,
  },
  {
    path: "models",
    component: ModelsComponent,
  },
  {
    path: "models/:modelId",
    component: ModelComponent,
  },
  {
    path: "models/:modelId/wargear/:wargearId",
    component: WargearComponent,
  },
  {
    path: "rostertypes",
    component: RosterTypesComponent,
  },
  {
    path: "rostertypes/:rostertypeId",
    component: RosterTypeComponent,
  },
  {
    path: "weapons",
    component: WeaponsComponent,
  },
  {
    path: "weapons/:weaponId",
    component: WeaponComponent,
  },
  {
    path: "weapons/:weaponId/profiles/:weaponProfileId",
    component: WeaponProfileComponent,
  },
  {
    path: "weapons/:weaponId/profiles/:weaponProfileId/modes/:weaponProfileModeId",
    component: WeaponProfileModeComponent,
  },
  {
    path: "weapons/groups/:weaponGroupId",
    component: WeaponGroupComponent,
  },
  {
    path: "weapons/types/:weaponProfileModeTypeId",
    component: WeaponProfileModeTypeComponent,
  },
  { path: "", redirectTo: "factions", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
