import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Attribute } from "src/app/models/attribute.model";
import { Faction } from "src/app/models/faction.model";
import { Model } from "src/app/models/model.model";
import { Patch } from "src/app/models/patch.model";
import { Upload } from "src/app/models/upload.model";
import { Wargear } from "src/app/models/wargear.model";
import { AttributeService } from "src/app/services/attribute.service";
import { FactionService } from "src/app/services/faction.service";
import { ModelService } from "src/app/services/model.service";

@Component({
  selector: "app-model",
  templateUrl: "./model.component.html",
  preserveWhitespaces: true,
})
export class ModelComponent {
  attributes: Attribute[];
  wargear: Wargear[] = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalTitle: string;
  modalWargear: Wargear[] = [];
  modalBody: string;
  modalId: string;

  selectedAttribute: Attribute;
  selectedWargear: Wargear;

  attributeDisplay: string = "0";

  model: Model;
  modelName: string = "Model";
  modelImagePath: string = "";

  alerts: Alert[] = [];

  filePath: string;

  hasLoadedModel: boolean = false;
  hasLoadedAttributes: boolean = false;
  hasLoadedWargear: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modelService: ModelService,
    private attributeService: AttributeService,
    private factionService: FactionService,
    private modalService: NgbModal
  ) {
    this.getModel();
  }

  getModel() {
    this.modelService
      .getFull<Model>({
        id: this.activatedRoute.snapshot.paramMap.get("modelId"),
      })
      .then(
        (result) => {
          this.model = result;
          this.hasLoadedModel = true;
          this.attributes = result.attributes;
          this.hasLoadedAttributes = true;
          this.wargear = result.wargear;
          this.hasLoadedWargear = true;
          this.setName();
          this.setPath();
        },
        (error) => {
          this.hasLoadedModel = false;
          this.hasLoadedAttributes = false;
          this.hasLoadedWargear = false;
          this.addAlert({
            type: "danger",
            message: "Model could not be retrieved!",
          });
        }
      );
  }

  refreshAttributes() {
    this.resetAttribute();
    this.attributes = [];
    this.modelService.getFull<Model>({ id: this.model.id }).then(
      (result) => {
        this.attributes = result.attributes;
        this.hasLoadedAttributes = true;
      },
      (error) => {
        this.hasLoadedAttributes = false;
        this.addAlert({
          type: "danger",
          message: "Attributes could not be retrieved!",
        });
      }
    );
  }

  setAttribute(id: string) {
    this.resetAttribute();
    this.selectedAttribute = this.attributes.find((row) => {
      return row.id === id;
    });
    this.setDisplay();
  }

  refreshWargear() {
    this.wargear = [];
    this.modelService.getFull<Model>({ id: this.model.id }).then(
      (result) => {
        this.wargear = result.wargear;
        this.hasLoadedWargear = true;
      },
      (error) => {
        this.hasLoadedWargear = false;
        this.addAlert({
          type: "danger",
          message: "Wargear could not be retrieved!",
        });
      }
    );
    this.resetNewWargear();
  }

  setWargear(id: string) {
    this.resetNewWargear();
    this.selectedWargear = this.wargear.find((row) => {
      return row.id === id;
    });
  }

  setName() {
    this.modelName = this.model.name;
  }

  setDisplay() {
    this.attributeDisplay = this.selectedAttribute.display;
  }

  setPath() {
    this.modelImagePath = this.model.imagePath
      ? this.modelService.baseUrl + this.model.imagePath
      : "";
  }

  setAttributeModal(id?: string, title?: string, body?: string) {
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  resetAttribute() {
    this.selectedAttribute = {
      id: "",
      name: "",
      description: "",
      shortName: "",
      display: "",
      roll: 0,
      sides: 0,
      multiply: 0,
      max: 0,
      min: 0,
      priority: 0,
      created: "",
      modified: "",
      isActive: true,
      useParent: false,
    };
  }

  patch(patch: Patch, field: string) {
    this.modelService.patch<Patch>([patch], { id: this.model.id }).subscribe(
      (result) => {
        this.addAlert({
          type: "success",
          message: 'Field: "' + field + '" was updated successfully!',
        });
        this.setName();
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: 'Field: "' + field + '" could not be updated!',
        });
      }
    );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.model.name },
      "Name"
    );
  }

  editValue() {
    this.patch(
      { op: "replace", path: "/value", value: this.model.value },
      "Value"
    );
  }

  setWargearModal(id?: string, title?: string, body?: string) {
    this.modalWargear = [];
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  setExistingWargearModal(id?: string, title?: string, body?: string) {
    this.factionService.getFull<Faction>({ id: this.model.faction.id }).then(
      (result) => {
        this.modalWargear = result.wargear.filter(
          (x) => !this.wargear.find((y) => y.id === x.id)
        );
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Wargear could not be retrieved!",
        });
      }
    );
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  resetNewWargear() {
    this.selectedWargear = {
      id: "",
      name: null,
      description: null,
      value: 0,
      created: "",
      modified: "",
      isActive: true,
      faction: null,
      weapons: null,
    };
  }

  resetExistingWargear() {
    this.selectedWargear = null;
  }

  uploadFile(files) {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    this.filePath = fileToUpload.name;
    const formData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);

    this.modelService.upload<Upload>(formData).subscribe((result) => {
      this.modelImagePath = this.modelService.baseUrl + result.dbPath;
      this.patch(
        { op: "replace", path: "/imagePath", value: result.dbPath },
        "Image"
      );
    });
  }

  newAttribute() {
    this.selectedAttribute.display = this.attributeDisplay;
    var value = this.attributeDisplay;
    var max = 0;
    var min = 0;
    var roll = 0;
    var sides = 0;
    var multiply = 1;
    var useParent = false;
    switch (true) {
      case value.indexOf("D") !== -1: {
        var values = value.split("D");
        min = Number(values[0]);
        max = min * Number(values[1]);
        roll = min;
        sides = Number(values[1]);
        break;
      }
      case value.indexOf("+") !== -1: {
        var values = value.split("+");
        min = Number(values[0]);
        roll = 1;
        sides = 6;
        break;
      }
      default: {
        max = value ? Number(value) : max;
        break;
      }
    }
    this.selectedAttribute = {
      id: this.selectedAttribute.id,
      name: this.selectedAttribute.name,
      description: this.selectedAttribute.description,
      shortName: this.selectedAttribute.shortName,
      display: value,
      roll: roll,
      sides: sides,
      multiply: multiply,
      max: max,
      min: min,
      priority: this.selectedAttribute.priority,
      created: "",
      modified: "",
      isActive: true,
      useParent: useParent,
    };
  }

  updateAttributeForm(content: any, value: string) {
    this.setAttribute(value);
    this.setAttributeModal(value, "Update " + this.selectedAttribute.name, "");
    this.modalService.open(content, { centered: true });
  }

  updateAttribute() {
    this.newAttribute();
    this.attributeService
      .put<Attribute>(this.selectedAttribute, { id: this.selectedAttribute.id })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message:
              'Attribute: "' +
              this.selectedAttribute.name +
              '" was updated successfully!',
          });
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Attribute: "' +
              this.selectedAttribute.name +
              '" could not be updated!',
          });
        }
      );
  }

  createWargearForm(content: any) {
    this.resetNewWargear();
    this.setWargearModal("", "Create a new Wargear", "");
    this.modalService.open(content, { centered: true });
  }

  createWargear() {
    this.selectedWargear.description = this.selectedWargear.name;
    this.modelService
      .putAddNewWargear<Model>(this.selectedWargear, {
        id: this.model.id,
        factionId: this.model.faction.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Wargear was created successfully!",
          });
          this.refreshWargear();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Wargear could not be created!",
          });
        }
      );
  }

  addWargearForm(content: any) {
    this.resetExistingWargear();
    this.setExistingWargearModal("", "Add a existing Wargear", "");
    this.modalService.open(content, { centered: true });
  }

  addWargear() {
    this.modelService
      .putAddExistingWargear<Model>(this.selectedWargear, {
        id: this.model.id,
        wargearId: this.selectedWargear.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Wargear was added successfully!",
          });
          this.refreshWargear();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Wargear could not be added!",
          });
        }
      );
  }

  deleteWargearConfirm(content: any, value: string) {
    this.setWargear(value);
    this.setWargearModal(
      value,
      "Remove " + this.selectedWargear.name,
      'Are you sure you want to remove Wargear: "' +
        this.selectedWargear.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.deleteWargear();
        }
      })
      .catch((res) => {});
  }

  deleteWargear() {
    this.modelService
      .putRemoveExistingWargear<Model>(this.selectedWargear, {
        id: this.model.id,
        wargearId: this.selectedWargear.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message:
              'Wargear: "' +
              this.selectedWargear.name +
              '" was removed successfully!',
          });
          this.wargear = this.wargear.filter(
            ({ id }) => id !== this.selectedWargear.id
          );
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Wargear: "' +
              this.selectedWargear.name +
              '" could not be removed!',
          });
        }
      );
  }

  viewWargear(value: any) {
    this.router.navigate([
      "admin/models/" + this.model.id + "/wargear/" + value,
    ]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
