import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { ColumnMode } from "@swimlane/ngx-datatable";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexStroke,
  ApexTheme,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from "ng-apexcharts";
import { Alert } from "src/app/models/alert.model";
import { GameModeFact } from "src/app/models/gamemodefact.model";
import { ModelFact } from "src/app/models/modelfact.model";
import { RosterFact } from "src/app/models/rosterfact.model";
import { WargearFact } from "src/app/models/wargearfact.model";
import { SimulationService } from "src/app/services/simulation.service";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  nonAxisSeries: ApexNonAxisChartSeries;
  colors: string[];
  fill: ApexFill;
  chart: ApexChart;
  stroke: ApexStroke;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  plotOptions: ApexPlotOptions;
  theme: ApexTheme;
  labels: string[];
};

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  preserveWhitespaces: true,
})
export class DashboardComponent implements AfterViewInit {
  @ViewChild("series1") series1: ChartComponent;
  @ViewChild("series2") series2: ChartComponent;
  @ViewChild("series3") series3: ChartComponent;
  @ViewChild("series4") series4: ChartComponent;
  @ViewChild("series5") series5: ChartComponent;
  @ViewChild("series6") series6: ChartComponent;
  @ViewChild("series7") series7: ChartComponent;
  @ViewChild("series8") series8: ChartComponent;
  @ViewChild("series9") series9: ChartComponent;
  @ViewChild("series10") series10: ChartComponent;

  public series1Chart: Partial<ChartOptions>;
  public series2Chart: Partial<ChartOptions>;
  public series3Chart: Partial<ChartOptions>;
  public series4Chart: Partial<ChartOptions>;
  public series5Chart: Partial<ChartOptions>;
  public series6Chart: Partial<ChartOptions>;
  public series7Chart: Partial<ChartOptions>;
  public series8Chart: Partial<ChartOptions>;
  public series9Chart: Partial<ChartOptions>;
  public series10Chart: Partial<ChartOptions>;

  gameModeFacts = [];
  modelFacts = [];
  rosterFacts = [];
  wargearFacts = [];

  topRosters = [];
  topRosterModels = [];

  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  alerts: Alert[] = [];

  hasLoadedGameModeFacts: boolean = false;
  hasLoadedModelFacts: boolean = false;
  hasLoadedRosterFacts: boolean = false;
  hasLoadedWargearFacts: boolean = false;

  constructor(private simulationService: SimulationService) {

  }

  ngAfterViewInit() {
    this.refreshGameModeFacts();
    this.refreshModelFacts();
    this.refreshRosterFacts();
    this.refreshWargearFacts();
  }

  refreshGameModeFacts() {
    this.gameModeFacts = [];
    this.simulationService.getDataGameModeFacts<GameModeFact[]>().then(
      (result) => {
        this.gameModeFacts = result;
        this.hasLoadedGameModeFacts = true;
        this.createSeries3();
        this.createSeries10();
      },
      (error) => {
        this.hasLoadedGameModeFacts = false;
        this.addAlert({
          type: "danger",
          message: "Game Mode Facts could not be retrieved!",
        });
      }
    );
  }

  refreshModelFacts() {
    this.modelFacts = [];
    this.simulationService.getDataModelFacts<ModelFact[]>().then(
      (result) => {
        this.modelFacts = result;
        this.hasLoadedModelFacts = true;
        this.createSeries6();
        this.createSeries7();
        this.createTopRosterModels();
      },
      (error) => {
        this.hasLoadedModelFacts = false;
        this.addAlert({
          type: "danger",
          message: "Model Facts could not be retrieved!",
        });
      }
    );
  }

  refreshRosterFacts() {
    this.rosterFacts = [];
    this.simulationService.getDataRosterFacts<RosterFact[]>().then(
      (result) => {
        this.rosterFacts = result;
        this.hasLoadedRosterFacts = true;
        this.createSeries4();
        this.createSeries5();
        this.createTopRosters();
      },
      (error) => {
        this.hasLoadedRosterFacts = false;
        this.addAlert({
          type: "danger",
          message: "Roster Facts could not be retrieved!",
        });
      }
    );
  }

  refreshWargearFacts() {
    this.wargearFacts = [];
    this.simulationService.getDataWargearFacts<WargearFact[]>().then(
      (result) => {
        this.wargearFacts = result;
        this.hasLoadedWargearFacts = true;
        this.createSeries1();
        this.createSeries2();
        this.createSeries8();
        this.createSeries9();
      },
      (error) => {
        this.hasLoadedWargearFacts = false;
        this.addAlert({
          type: "danger",
          message: "Wargear Facts could not be retrieved!",
        });
      }
    );
  }

  createSeries1() {
    var series: ApexAxisChartSeries = [{ name: "Hits", data: [] }];
    var xaxis: ApexXAxis = { categories: [] };
    var colors: string[] = ["#66d1d1"];

    const result = [
      ...this.wargearFacts
        .reduce((r, o) => {
          const key = o.date.name;
          const item = r.get(key) || Object.assign({}, o, { hits: 0 });
          item.hits += o.hits;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    result.sort((a, b) => (a.date.name > b.date.name ? 1 : -1));

    for (var i = 0; i < result.length && i < 10; i++) {
      if (result[i].hits > 0) {
        series[0].data.push(result[i].hits);
        xaxis.categories.push(result[i].date.name);
      }
    }

    this.series1Chart = {
      series: series,
      stroke: { curve: "straight" },
      chart: {
        height: 150,
        type: "area",
        sparkline: { enabled: true },
        toolbar: { show: false },
      },
      xaxis: xaxis,
      colors: colors,
      theme: { mode: "dark" },
    };
  }

  createSeries2() {
    var series: ApexAxisChartSeries = [{ name: "Wounds", data: [] }];
    var xaxis: ApexXAxis = { categories: [] };
    var colors: string[] = ["#66d1d1"];

    const result = [
      ...this.wargearFacts
        .reduce((r, o) => {
          const key = o.date.name;
          const item = r.get(key) || Object.assign({}, o, { wounds: 0 });
          item.wounds += o.wounds;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    result.sort((a, b) => (a.date.name > b.date.name ? 1 : -1));

    for (var i = 0; i < result.length && i < 10; i++) {
      if (result[i].wounds > 0) {
        series[0].data.push(result[i].wounds);
        xaxis.categories.push(result[i].date.name);
      }
    }

    this.series2Chart = {
      series: series,
      stroke: { curve: "straight" },
      chart: {
        height: 150,
        type: "area",
        sparkline: { enabled: true },
        toolbar: { show: false },
      },
      xaxis: xaxis,
      colors: colors,
      theme: { mode: "dark" },
    };
  }

  createSeries3() {
    var series: ApexAxisChartSeries = [{ name: "Kills", data: [] }];
    var xaxis: ApexXAxis = { categories: [] };
    var colors: string[] = ["#66d1d1"];

    const result = [
      ...this.gameModeFacts
        .reduce((r, o) => {
          const key = o.date.name;
          const item = r.get(key) || Object.assign({}, o, { kills: 0 });
          item.kills += o.kills;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    result.sort((a, b) => (a.date.name > b.date.name ? 1 : -1));

    for (var i = 0; i < result.length && i < 10; i++) {
      if (result[i].kills > 0) {
        series[0].data.push(result[i].kills);
        xaxis.categories.push(result[i].date.name);
      }
    }

    this.series3Chart = {
      series: series,
      stroke: { curve: "straight" },
      chart: {
        height: 150,
        type: "area",
        sparkline: { enabled: true },
        toolbar: { show: false },
      },
      xaxis: xaxis,
      colors: colors,
      theme: { mode: "dark" },
    };
  }

  createSeries4() {
    var series: ApexNonAxisChartSeries = [];
    var labels: string[] = [];
    var colors: string[] = [];

    const result = [
      ...this.rosterFacts
        .reduce((r, o) => {
          const key = o.faction.name;
          const item = r.get(key) || Object.assign({}, o, { victoryPoints: 0 });
          item.victoryPoints += o.victoryPoints;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    result.sort((a, b) => (a.victoryPoints < b.victoryPoints ? 1 : -1));

    for (var i = 0; i < result.length && i < 10; i++) {
      if (result[i].victoryPoints > 0) {
        series.push(result[i].victoryPoints);
        labels.push(result[i].faction.name);
        colors.push(result[i].faction.colour);
      }
    }

    this.series4Chart = {
      nonAxisSeries: series,
      chart: { height: 350, type: "pie", toolbar: { show: false } },
      labels: labels,
      colors: colors,
      theme: { mode: "dark" },
    };
  }

  createSeries5() {
    var series: ApexAxisChartSeries = [{ name: "Victory Points", data: [] }];
    var xaxis: ApexXAxis = { categories: [] };
    var colors: string[] = [];

    const result = [
      ...this.rosterFacts
        .reduce((r, o) => {
          const key = o.branch.name;
          const item = r.get(key) || Object.assign({}, o, { victoryPoints: 0 });
          item.victoryPoints += o.victoryPoints;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    result.sort((a, b) => (a.victoryPoints < b.victoryPoints ? 1 : -1));

    for (var i = 0; i < result.length && i < 5; i++) {
      if (result[i].victoryPoints > 0) {
        series[0].data.push(result[i].victoryPoints);
        xaxis.categories.push(result[i].branch.name);
        colors.push(result[i].faction.colour);
      }
    }

    this.series5Chart = {
      series: series,
      chart: { height: 350, type: "bar", toolbar: { show: false } },
      plotOptions: { bar: { distributed: true } },
      xaxis: xaxis,
      colors: colors,
      theme: { mode: "dark" },
    };
  }

  createSeries6() {
    var series: ApexAxisChartSeries = [{ name: "Victory Points", data: [] }];
    var xaxis: ApexXAxis = { categories: [] };
    var colors: string[] = [];

    const result = [
      ...this.modelFacts
        .reduce((r, o) => {
          const key = o.model.name;
          const item = r.get(key) || Object.assign({}, o, { victoryPoints: 0 });
          item.victoryPoints += o.victoryPoints;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    result.sort((a, b) => (a.victoryPoints < b.victoryPoints ? 1 : -1));

    for (var i = 0; i < result.length && i < 10; i++) {
      if (result[i].victoryPoints > 0) {
        series[0].data.push(result[i].victoryPoints);
        xaxis.categories.push(result[i].model.name);
        colors.push(result[i].faction.colour);
      }
    }

    this.series6Chart = {
      series: series,
      chart: { height: 500, type: "bar", toolbar: { show: false } },
      plotOptions: { bar: { horizontal: true, distributed: true } },
      xaxis: xaxis,
      colors: colors,
      theme: { mode: "dark" },
    };
  }

  createSeries7() {
    var series: ApexAxisChartSeries = [{ name: "Kill/Death Ratio", data: [] }];
    var xaxis: ApexXAxis = { categories: [] };
    var colors: string[] = [];

    const result = [
      ...this.modelFacts
        .reduce((r, o) => {
          const key = o.model.name;
          const item =
            r.get(key) || Object.assign({}, o, { kills: 0, deaths: 0 });
          item.kills += o.kills;
          item.deaths += o.deaths;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    result.sort((a, b) => (a.kills / a.deaths < b.kills / b.deaths ? 1 : -1));

    var ratio: any;

    for (var i = 0; i < result.length && i < 10; i++) {
      if (result[i].kills > 0) {
        ratio = (result[i].kills / result[i].deaths).toFixed(2);
        series[0].data.push(ratio);
        xaxis.categories.push(result[i].model.name);
        colors.push(result[i].faction.colour);
      }
    }

    this.series7Chart = {
      series: series,
      chart: { height: 500, type: "bar", toolbar: { show: false } },
      plotOptions: { bar: { distributed: true } },
      xaxis: xaxis,
      colors: colors,
      theme: { mode: "dark" },
    };
  }

  createSeries8() {
    var series: ApexAxisChartSeries = [{ name: "Wounds", data: [] }];
    var xaxis: ApexXAxis = { categories: [] };
    var colors: string[] = [];

    const result = [
      ...this.wargearFacts
        .reduce((r, o) => {
          const key = o.weapon.name + "-" + o.faction.name;
          const item = r.get(key) || Object.assign({}, o, { wounds: 0 });
          item.wounds += o.wounds;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    result.sort((a, b) => (a.wounds < b.wounds ? 1 : -1));

    for (var i = 0; i < result.length && i < 10; i++) {
      if (result[i].wounds > 0) {
        series[0].data.push(result[i].wounds);
        xaxis.categories.push(result[i].weapon.name);
        colors.push(result[i].faction.colour);
      }
    }

    this.series8Chart = {
      series: series,
      chart: { height: 500, type: "bar", toolbar: { show: false } },
      plotOptions: { bar: { horizontal: true, distributed: true } },
      xaxis: xaxis,
      colors: colors,
      theme: { mode: "dark" },
    };
  }

  createSeries9() {
    var series: ApexAxisChartSeries = [{ name: "Hit/Fail Ratio", data: [] }];
    var xaxis: ApexXAxis = { categories: [] };
    var colors: string[] = [];

    const result = [
      ...this.wargearFacts
        .reduce((r, o) => {
          const key = o.weapon.name + "-" + o.faction.name;
          const item =
            r.get(key) || Object.assign({}, o, { hits: 0, fails: 0 });
          item.hits += o.hits;
          item.fails += o.fails;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    result.sort((a, b) => (a.hits / a.fails < b.hits / b.fails ? 1 : -1));

    var ratio: any;

    for (var i = 0; i < result.length && i < 10; i++) {
      if (result[i].hits > 0) {
        ratio = (result[i].hits / result[i].fails).toFixed(2);
        series[0].data.push(ratio);
        xaxis.categories.push(result[i].weapon.name);
        colors.push(result[i].faction.colour);
      }
    }

    this.series9Chart = {
      series: series,
      chart: { height: 500, type: "bar", toolbar: { show: false } },
      plotOptions: { bar: { distributed: true } },
      xaxis: xaxis,
      colors: colors,
      theme: { mode: "dark" },
    };
  }

  createSeries10() {
    var series: ApexAxisChartSeries = [{ name: "Kills", data: [] }];
    var xaxis: ApexXAxis = { categories: [] };
    var colors: string[] = ["#66d1d1"];

    const result = [
      ...this.gameModeFacts
        .reduce((r, o) => {
          const key = o.action.name;
          const item = r.get(key) || Object.assign({}, o, { kills: 0 });
          item.kills += o.kills;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    result.sort((a, b) => (a.action.priority > b.action.priority ? 1 : -1));
    result.sort((a, b) => (a.phase.priority > b.phase.priority ? 1 : -1));

    for (var i = 0; i < result.length; i++) {
      if (result[i].kills > 0) {
        series[0].data.push(result[i].kills);
        xaxis.categories.push(result[i].action.name);
      }
    }

    this.series10Chart = {
      series: series,
      chart: { height: 350, type: "bar", toolbar: { show: false } },
      xaxis: xaxis,
      colors: colors,
      theme: { mode: "dark" },
    };
  }

  createTopRosters() {
    const result = [
      ...this.rosterFacts
        .reduce((r, o) => {
          const key = o.roster.key;
          const item = r.get(key) || Object.assign({}, o, { victoryPoints: 0 });
          item.victoryPoints += o.victoryPoints;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    result.sort((a, b) => (a.victoryPoints < b.victoryPoints ? 1 : -1));

    this.topRosters = result.slice(0, 10);
  }

  createTopRosterModels() {
    const result = [
      ...this.modelFacts
        .reduce((r, o) => {
          const key = o.rosterModel.key;
          const item = r.get(key) || Object.assign({}, o, { victoryPoints: 0 });
          item.victoryPoints += o.victoryPoints;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    result.sort((a, b) => (a.victoryPoints < b.victoryPoints ? 1 : -1));

    this.topRosterModels = result.slice(0, 10);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
