import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class ActionOptionService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  get<ActionOption>(ids): Promise<ActionOption> {
    var url = this.baseUrl + "api/ActionOptions/" + ids.id + this.getCacheBust;
    return this.http.get<ActionOption>(url).toPromise();
  }

  getFull<ActionOption>(ids): Promise<ActionOption> {
    var url = this.baseUrl + "api/ActionOptions/" + ids.id + "/Full" + this.getCacheBust;
    return this.http.get<ActionOption>(url).toPromise();
  }

  put<ActionOption>(item, ids): Observable<ActionOption> {
    var url = this.baseUrl + "api/ActionOptions/" + ids.id;
    return this.http.put<ActionOption>(url, item);
  }

  putPriority<ActionOption>(item, ids): Observable<ActionOption> {
    var url = this.baseUrl + "api/ActionOptions/" + ids.id + "/Target/" + ids.targetId;
    return this.http.put<ActionOption>(url, item);
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/ActionOptions/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<ActionOption>(item, ids): Observable<ActionOption> {
    var url = this.baseUrl + "api/ActionOptions/" + ids.id + "/Action/" + ids.actionId;
    return this.http.post<ActionOption>(url, item);
  }

  delete<ActionOption>(ids): Observable<ActionOption> {
    var url = this.baseUrl + "api/ActionOptions/" + ids.id;
    return this.http.delete<ActionOption>(url);
  }
}
