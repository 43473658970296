import { AuthConfig } from "angular-oauth2-oidc";

export const authConfig: AuthConfig = {
  issuer: "https://accounts.christiandicks.co.za",

  clientId: "ubwhsclient",

  responseType: "code",

  redirectUri: window.location.origin + "/index.html",

  silentRefreshRedirectUri: window.location.origin + "/silent-refresh.html",

  scope: "openid profile roles ubwhsapi.scope",

  useSilentRefresh: true,

  //silentRefreshTimeout: 5000, // For faster testing

  //timeoutFactor: 0.25, // For faster testing

  sessionChecksEnabled: true,

  //showDebugInformation: true,

  clearHashAfterLogin: false,

  nonceStateSeparator: "semicolon",
};
