import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Action } from "src/app/models/action.model";
import { Alert } from "src/app/models/alert.model";
import { GameMode } from "src/app/models/gamemode.model";
import { Patch } from "src/app/models/patch.model";
import { Phase } from "src/app/models/phase.model";
import { ActionService } from "src/app/services/action.service";
import { PhaseService } from "src/app/services/phase.service";

@Component({
  selector: "app-phase",
  templateUrl: "./phase.component.html",
  preserveWhitespaces: true,
})
export class PhaseComponent {
  actions = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalTitle: string;
  modalBody: string;
  modalId: string;

  selectedAction: Action;

  gameMode: GameMode;
  gameModeId: string = "";
  gameModeName: string = "Game Mode";

  phase: Phase;
  phaseName: string = "Phase";
  phaseImagePath: string;

  prevAction: Action;

  alerts: Alert[] = [];

  filePath: string;

  hasLoadedPhase: boolean = false;
  hasLoadedActions: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private phaseService: PhaseService,
    private actionService: ActionService,
    private modalService: NgbModal
  ) {
    this.getPhase();
  }

  getPhase() {
    this.phaseService
      .getFull<Phase>({
        id: this.activatedRoute.snapshot.paramMap.get("phaseId"),
      })
      .then(
        (result) => {
          this.phase = result;
          this.gameMode = this.phase.gameMode;
          this.gameModeId = this.gameMode.id;
          this.gameModeName = this.gameMode.name;
          this.hasLoadedPhase = true;
          this.actions = this.phase.actions;
          this.linkActions();
          this.hasLoadedActions = true;
          this.setName();
        },
        (error) => {
          this.hasLoadedPhase = false;
          this.hasLoadedActions = false;
          this.addAlert({
            type: "danger",
            message: "Phase could not be retrieved!",
          });
        }
      );
  }

  refresh() {
    this.resetAction();
    this.actions = [];
    this.phaseService.getFull<Phase>({ id: this.phase.id }).then(
      (result) => {
        this.actions = result.actions;
        this.linkActions();
        this.hasLoadedActions = true;
      },
      (error) => {
        this.hasLoadedActions = false;
        this.addAlert({
          type: "danger",
          message: "Actions could not be retrieved!",
        });
      }
    );
  }

  linkActions() {
    for (let i = 0; i < this.actions.length; i++) {
      this.actions[i].nextAction =
        i < this.actions.length ? this.actions[i + 1] : null;
      this.actions[i].previousAction = i > 0 ? this.actions[i - 1] : null;
    }
  }

  setAction(id: string) {
    this.resetAction();
    this.selectedAction = this.actions.find((action) => {
      return action.id === id;
    });
  }

  resetAction() {
    this.selectedAction = {
      id: "",
      name: null,
      description: null,
      priority: 0,
      created: "",
      modified: "",
      isActive: false,
      previousAction: null,
      nextAction: null,
      phase: null,
      actionOptions: null,
    };
  }

  setName() {
    this.phaseName = this.phase.name;
  }

  setActionModal(id?: string, title?: string, body?: string) {
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  patch(patch: Patch, field: string) {
    this.phaseService.patch<Patch>([patch], { id: this.phase.id }).subscribe(
      (result) => {
        this.addAlert({
          type: "success",
          message: 'Field: "' + field + '" was updated successfully!',
        });
        this.setName();
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: 'Field: "' + field + '" could not be updated!',
        });
      }
    );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.phase.name },
      "Name"
    );
  }

  createForm(content: any) {
    this.resetAction();
    this.setActionModal("", "Create a new Action", "");
    this.modalService.open(content, { centered: true });
  }

  create() {
    this.selectedAction.description = this.selectedAction.name;
    this.prevAction = this.actions
      .reverse()
      .filter((x) => x.nextAction == null)[0];
    this.actionService
      .post<Action>(this.selectedAction, {
        phaseId: this.phase.id,
        id: this.prevAction ? this.prevAction.id : "New",
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Action was created successfully!",
          });
          this.refresh();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Action could not be created!",
          });
        }
      );
  }

  toggleActionConfirm(content: any, value: string) {
    this.setAction(value);
    this.setActionModal(
      value,
      (this.selectedAction.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedAction.name,
      "Are you sure you want to " +
        (this.selectedAction.isActive ? "disable" : "enable") +
        ' Action: "' +
        this.selectedAction.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleAction();
        }
      })
      .catch((res) => {});
  }

  toggleAction() {
    this.actionService
      .patch<Patch>(
        [
          {
            op: "replace",
            path: "/isActive",
            value: String(!this.selectedAction.isActive),
          },
        ],
        { id: this.selectedAction.id }
      )
      .subscribe(
        (result) => {
          this.selectedAction.isActive = !this.selectedAction.isActive;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Field: "' + this.selectedAction.name + '" could not be updated!',
          });
        }
      );
  }

  prev(action: Action) {
    this.update(
      {
        id: action.id,
        name: action.name,
        description: action.description,
        priority: action.priority,
        isActive: action.isActive,
      },
      this.phase.id,
      action.previousAction.id
    );
  }

  next(action: Action) {
    this.update(
      {
        id: action.id,
        name: action.name,
        description: action.description,
        priority: action.priority,
        isActive: action.isActive,
      },
      this.phase.id,
      action.nextAction.id
    );
  }

  update(action: any, phaseId: string, targetId: string) {
    this.actionService
      .putPriority<Action>(action, {
        phaseId: phaseId,
        targetId: targetId,
        id: action.id,
      })
      .subscribe(
        (result) => {
          this.refresh();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Priority could not be updated! Error: " + error,
          });
        }
      );
  }

  view(value: any) {
    this.router.navigate([
      "admin/gamemodes/" +
        this.gameMode.id +
        "/phases/" +
        this.phase.id +
        "/actions/" +
        value,
    ]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
