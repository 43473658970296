import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HistoryComponent } from "./history/history.component";
import { ProcessComponent } from "./process/process.component";
import { ResultLogComponent } from "./resultlog/resultlog.component";
import { ResultsComponent } from "./results/results.component";
import { SimulationComponent } from "./simulation/simulation.component";

const routes: Routes = [
  {
    path: "run",
    component: SimulationComponent,
  },
  {
    path: "history",
    component: HistoryComponent,
  },
  {
    path: "history/:simulationId",
    component: ProcessComponent,
  },
  {
    path: "history/:simulationId/results",
    component: ResultsComponent,
  },
  {
    path: "history/:simulationId/results/:matchId",
    component: ResultLogComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SimulationRoutingModule {}
