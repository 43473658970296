import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { GameMode } from "src/app/models/gamemode.model";
import { Patch } from "src/app/models/patch.model";
import { GameModeService } from "src/app/services/gamemode.service";

@Component({
  selector: "app-gamemodes",
  templateUrl: "./gamemodes.component.html",
  preserveWhitespaces: true,
})
export class GameModesComponent {
  gameModes = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalTitle: string;
  modalBody: string;
  modalId: string;

  selectedGameMode: GameMode;

  alerts: Alert[] = [];

  hasLoadedGameModes: boolean = false;

  constructor(
    private router: Router,
    private gameModeService: GameModeService,
    private modalService: NgbModal
  ) {
    this.refresh();
  }

  refresh() {
    this.resetGameMode();
    this.gameModes = [];
    this.gameModeService.getData<GameMode[]>().then(
      (result) => {
        this.gameModes = result;
        this.hasLoadedGameModes = true;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Game Modes could not be retrieved!",
        });
        this.hasLoadedGameModes = false;
      }
    );
  }

  setGameMode(id: string) {
    this.resetGameMode();
    this.selectedGameMode = this.gameModes.find((gameMode) => {
      return gameMode.id === id;
    });
  }

  setGameModeModal(id?: string, title?: string, body?: string) {
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  resetGameMode() {
    this.selectedGameMode = {
      id: "",
      name: null,
      description: null,
      imagePath: "",
      created: "",
      modified: "",
      isActive: false,
      factions: null,
      phases: null,
      rosterTypes: null,
    };
  }

  createForm(content: any, value: string) {
    this.resetGameMode();
    this.setGameModeModal("", "Create a new Game Mode", "");
    this.modalService.open(content, { centered: true });
  }

  create() {
    this.selectedGameMode.description = this.selectedGameMode.name;
    this.gameModeService
      .post<GameMode>(this.selectedGameMode)
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Game Mode was created successfully!",
          });
          this.refresh();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Game Mode could not be created!",
          });
        }
      );
  }

  toggleGameModeConfirm(content: any, value: string) {
    this.setGameMode(value);
    this.setGameModeModal(
      value,
      (this.selectedGameMode.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedGameMode.name,
      "Are you sure you want to " +
        (this.selectedGameMode.isActive ? "disable" : "enable") +
        ' Game Mode: "' +
        this.selectedGameMode.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleGameMode();
        }
      })
      .catch((res) => {});
  }

  toggleGameMode() {
    this.gameModeService
      .patch<Patch>(
        [
          {
            op: "replace",
            path: "/isActive",
            value: String(!this.selectedGameMode.isActive),
          },
        ],
        { id: this.selectedGameMode.id }
      )
      .subscribe(
        (result) => {
          this.selectedGameMode.isActive = !this.selectedGameMode.isActive;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Field: "' +
              this.selectedGameMode.name +
              '" could not be updated!',
          });
        }
      );
  }

  view(value: any) {
    this.router.navigate(["admin/gamemodes/" + value]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
