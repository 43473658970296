import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class FactionFirstNameService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  get<FactionFirstName>(ids): Promise<FactionFirstName> {
    var url = this.baseUrl + "api/FactionFirstNames/" + ids.id + this.getCacheBust;
    return this.http.get<FactionFirstName>(url).toPromise();
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/FactionFirstNames/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<FactionFirstName>(item, ids): Observable<FactionFirstName> {
    var url = this.baseUrl + "api/FactionFirstNames/New/Faction/" + ids.factionId;
    return this.http.post<FactionFirstName>(url, item);
  }
}
