import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { GameMode } from "src/app/models/gamemode.model";
import { Patch } from "src/app/models/patch.model";
import { RosterType } from "src/app/models/rostertype.model";
import { GameModeService } from "src/app/services/gamemode.service";
import { RosterTypeService } from "src/app/services/rostertype.service";

@Component({
  selector: "app-rostertypes",
  templateUrl: "./rostertypes.component.html",
  preserveWhitespaces: true,
})
export class RosterTypesComponent {
  types = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalGameModes: GameMode[];
  modalTitle: string;
  modalBody: string;
  modalId: string;

  selectedType: RosterType;

  alerts: Alert[] = [];

  hasLoadedTypes: boolean = false;

  constructor(
    private router: Router,
    private gameModeService: GameModeService,
    private typeService: RosterTypeService,
    private modalService: NgbModal
  ) {
    this.refreshType();
  }

  refreshType() {
    this.resetType();
    this.types = [];
    this.typeService.getData<RosterType[]>().then(
      (result) => {
        this.types = result;
        this.resetType();
        this.hasLoadedTypes = true;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Types could not be retrieved!",
        });
        this.hasLoadedTypes = false;
      }
    );
  }

  setType(id: string) {
    this.resetType();
    this.selectedType = this.types.find((type) => {
      return type.id === id;
    });
  }

  setTypeModal(id?: string, title?: string, body?: string) {
    this.gameModeService.getData<GameMode[]>().then(
      (result) => {
        this.modalGameModes = result;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Game Modes could not be retrieved!",
        });
      }
    );
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  resetType() {
    this.selectedType = {
      id: "",
      name: null,
      description: null,
      value: null,
      created: "",
      modified: "",
      isActive: false,
      gameMode: null,
    };
  }

  createTypeForm(content: any, value: string) {
    this.resetType();
    this.setTypeModal("", "Create a new Type", "");
    this.modalService.open(content, { centered: true });
  }

  createType() {
    this.selectedType.description = this.selectedType.name;
    this.typeService
      .post<RosterType>(this.selectedType, {
        gameModeId: this.selectedType.gameMode.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Type was created successfully!",
          });
          this.refreshType();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Type could not be created!",
          });
        }
      );
  }

  toggleTypeConfirm(content: any, value: string) {
    this.setType(value);
    this.setTypeModal(
      value,
      (this.selectedType.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedType.name,
      "Are you sure you want to " +
        (this.selectedType.isActive ? "disable" : "enable") +
        ' Type: "' +
        this.selectedType.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleType();
        }
      })
      .catch((res) => {});
  }

  toggleType() {
    this.typeService
      .patch<Patch>(
        [
          {
            op: "replace",
            path: "/isActive",
            value: String(!this.selectedType.isActive),
          },
        ],
        { id: this.selectedType.id }
      )
      .subscribe(
        (result) => {
          this.selectedType.isActive = !this.selectedType.isActive;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Field: "' + this.selectedType.name + '" could not be updated!',
          });
        }
      );
  }

  viewType(value: any) {
    this.router.navigate(["admin/rostertypes/" + value]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
