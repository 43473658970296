import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export interface BaseService {
  getData<T>(ids: any): Promise<T>;

  get<T>(ids: any): Promise<T>;

  getFull?<T>(ids: any): Promise<T>;

  getWithChildren?<T>(ids: any): Promise<T>;

  getWithParent?<T>(ids: any): Promise<T>;

  putPriority?<T>(item: T, ids: any): Observable<T>;

  put<T>(item: T, ids: any): Observable<T>;

  patch<T>(item: T, ids: any): Observable<T>;

  post<T>(item: T, ids: any): Observable<T>;

  delete<T>(ids: any): Observable<T>;

  upload<T>(item: T): Observable<T>;
}

@Injectable()
export abstract class BaseService {
  baseUrl: string;

  constructor(public http: HttpClient) {
    this.baseUrl = "https://api.christiandicks.co.za/";
  }

  get getCacheBust() {
    return "?t=" + new Date().getTime();
  }
}
