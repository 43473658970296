import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class WeaponProfileModeTypeService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getData<T>(): Promise<T> {
    var url = this.baseUrl + "api/WeaponProfileModeTypes/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  get<WeaponProfileModeType>(ids): Promise<WeaponProfileModeType> {
    var url =
      this.baseUrl + "api/WeaponProfileModeTypes/" + ids.id + this.getCacheBust;
    return this.http.get<WeaponProfileModeType>(url).toPromise();
  }

  getFull<WeaponProfileModeType>(ids): Promise<WeaponProfileModeType> {
    var url = this.baseUrl + "api/WeaponProfileModeTypes/" + ids.id + "/Full" + this.getCacheBust;
    return this.http.get<WeaponProfileModeType>(url).toPromise();
  }

  put<WeaponProfileModeType>(item, ids): Observable<WeaponProfileModeType> {
    var url = this.baseUrl + "api/WeaponProfileModeTypes/" + ids.id;
    return this.http.put<WeaponProfileModeType>(url, item);
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/WeaponProfileModeTypes/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<WeaponProfileModeType>(item): Observable<WeaponProfileModeType> {
    var url = this.baseUrl + "api/WeaponProfileModeTypes/";
    return this.http.post<WeaponProfileModeType>(url, item);
  }

  delete<WeaponProfileModeType>(ids): Observable<WeaponProfileModeType> {
    var url = this.baseUrl + "api/WeaponProfileModeTypes/" + ids.id;
    return this.http.delete<WeaponProfileModeType>(url);
  }
}
