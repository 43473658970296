import { DOCUMENT } from "@angular/common";
import { Component, Inject, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/auth/auth.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
})
export class NavbarComponent {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private renderer: Renderer2,
    private router: Router
  ) {}

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logoutExternally();
    localStorage.clear();
    this.router.navigate(["/auth/access"]);
  }

  userClaim(item: string): string {
    return this.authService.identityClaim(item);
  }

  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle("sidebar-open");
  }
}
