import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Alert } from "src/app/models/alert.model";
import { Branch } from "src/app/models/branch.model";
import { BranchFirstName } from "src/app/models/branchfirstname.model";
import { Faction } from "src/app/models/faction.model";
import { Patch } from "src/app/models/patch.model";
import { BranchFirstNameService } from "src/app/services/branchfirstname.service";

@Component({
  selector: "app-branchfirstname",
  templateUrl: "./branchfirstname.component.html",
  preserveWhitespaces: true,
})
export class BranchFirstNameComponent {
  faction: Faction;
  factionId: string = "";
  factionName: string = "Faction";

  branch: Branch;
  branchId: string = "";
  branchName: string = "Branch";

  firstName: BranchFirstName;
  firstNameName: string = "FirstName";

  alerts: Alert[] = [];

  filePath: string;

  hasLoadedFirstName: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private branchFirstNameService: BranchFirstNameService
  ) {
    this.getFirstName();
  }

  getFirstName() {
    this.branchFirstNameService
      .get<BranchFirstName>({
        id: this.activatedRoute.snapshot.paramMap.get("firstNameId"),
      })
      .then(
        (result) => {
          this.firstName = result;
          this.branch = this.firstName.branch;
          this.branchId = this.branch.id;
          this.branchName = this.branch.name;
          this.faction = this.branch.faction;
          this.factionId = this.faction.id;
          this.factionName = this.faction.name;
          this.hasLoadedFirstName = true;
          this.setName();
        },
        (error) => {
          this.hasLoadedFirstName = false;
          this.addAlert({
            type: "danger",
            message: "First Name could not be retrieved!",
          });
        }
      );
  }

  setName() {
    this.firstNameName = this.firstName.name;
  }

  patch(patch: Patch, field: string) {
    this.branchFirstNameService
      .patch<Patch>([patch], { id: this.firstName.id })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: 'Field: "' + field + '" was updated successfully!',
          });
          this.setName();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: 'Field: "' + field + '" could not be updated!',
          });
        }
      );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.firstName.name },
      "Name"
    );
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
