import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class RosterService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getData<T>(): Promise<T> {
    var url = this.baseUrl + "api/Rosters/" + this.getCacheBust;
    return this.http.get<T>(url).toPromise();
  }

  get<Roster>(ids): Promise<Roster> {
    var url = this.baseUrl + "api/Rosters/" + ids.id + this.getCacheBust;
    return this.http.get<Roster>(url).toPromise();
  }

  getFull<Roster>(ids): Promise<Roster> {
    var url = this.baseUrl + "api/Rosters/" + ids.id + "/Full" + this.getCacheBust;
    return this.http.get<Roster>(url).toPromise();
  }

  put<Roster>(item, ids): Observable<Roster> {
    var url = this.baseUrl + "api/Rosters/" + ids.id;
    return this.http.put<Roster>(url, item);
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/Rosters/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<Roster>(item, ids): Observable<Roster> {
    var url = this.baseUrl + "api/Rosters/New/Branch/" + ids.branchId + "/Faction/" + ids.factionId + "/GameMode/" + ids.gameModeId + "/Type/" + ids.rosterTypeId;
    return this.http.post<Roster>(url, item);
  }

  delete<Roster>(ids): Observable<Roster> {
    var url = this.baseUrl + "api/Rosters/" + ids.id;
    return this.http.delete<Roster>(url);
  }
}
