import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class BranchFirstNameService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  get<BranchFirstName>(ids): Promise<BranchFirstName> {
    var url = this.baseUrl + "api/BranchFirstNames/" + ids.id + this.getCacheBust;
    return this.http.get<BranchFirstName>(url).toPromise();
  }

  patch<T>(item, ids): Observable<T> {
    var url = this.baseUrl + "api/BranchFirstNames/" + ids.id;
    return this.http.patch<T>(url, item, {
      headers: new HttpHeaders({ Accept: "application/json-patch+json" }),
    });
  }

  post<BranchFirstName>(item, ids): Observable<BranchFirstName> {
    var url = this.baseUrl + "api/BranchFirstNames/New/Branch/" + ids.branchId;
    return this.http.post<BranchFirstName>(url, item);
  }
}
