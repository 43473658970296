import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Alert } from "src/app/models/alert.model";
import { Faction } from "src/app/models/faction.model";
import { GameMode } from "src/app/models/gamemode.model";
import { Patch } from "src/app/models/patch.model";
import { FactionService } from "src/app/services/faction.service";
import { GameModeService } from "src/app/services/gamemode.service";

@Component({
  selector: "app-factions",
  templateUrl: "./factions.component.html",
  preserveWhitespaces: true,
})
export class FactionsComponent {
  factions = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalGameModes: GameMode[];
  modalTitle: string;
  modalBody: string;
  modalId: string;

  selectedFaction: Faction;
  selectedGameMode: GameMode;

  alerts: Alert[] = [];

  hasLoadedFactions: boolean = false;

  constructor(
    private router: Router,
    private factionService: FactionService,
    private gameModeService: GameModeService,
    private modalService: NgbModal
  ) {
    this.refreshFactions();
  }

  refreshFactions() {
    this.resetFaction();
    this.factions = [];
    this.factionService.getData<Faction[]>().then(
      (result) => {
        this.factions = result;
        this.hasLoadedFactions = true;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Factions could not be retrieved!",
        });
        this.hasLoadedFactions = false;
      }
    );
  }

  setFaction(id: string) {
    this.resetFaction();
    this.selectedFaction = this.factions.find((faction) => {
      return faction.id === id;
    });
  }

  setFactionModal(id?: string, title?: string, body?: string) {
    this.gameModeService.getData<GameMode[]>().then(
      (result) => {
        this.modalGameModes = result;
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: "Game Modes could not be retrieved!",
        });
      }
    );
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  resetFaction() {
    this.selectedFaction = {
      id: "",
      name: null,
      description: null,
      colour: "",
      imagePath: "",
      created: "",
      modified: "",
      isActive: false,
      branches: null,
      factionFirstNames: null,
      factionLastNames: null,
      gameMode: null,
      models: null,
      wargear: null,
      weapons: null,
    };
  }

  resetGameMode() {
    this.selectedGameMode = {
      id: "",
      name: null,
      description: null,
      imagePath: "",
      created: "",
      modified: "",
      isActive: true,
      factions: null,
      phases: null,
      rosterTypes: null,
    };
  }

  createForm(content: any, value: string) {
    this.resetFaction();
    this.setFactionModal("", "Create a new Faction", "");
    this.modalService.open(content, { centered: true });
  }

  create() {
    this.selectedFaction.description = this.selectedFaction.name;
    this.factionService
      .post<Faction>(this.selectedFaction, {
        gameModeId: this.selectedFaction.gameMode.id,
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Faction was created successfully!",
          });
          this.refreshFactions();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Faction could not be created!",
          });
        }
      );
  }

  toggleFactionConfirm(content: any, value: string) {
    this.setFaction(value);
    this.setFactionModal(
      value,
      (this.selectedFaction.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedFaction.name,
      "Are you sure you want to " +
        (this.selectedFaction.isActive ? "disable" : "enable") +
        ' Faction: "' +
        this.selectedFaction.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleFaction();
        }
      })
      .catch((res) => {});
  }

  toggleFaction() {
    if (this.selectedFaction.imagePath != "")
      this.factionService
        .patch<Patch>(
          [
            {
              op: "replace",
              path: "/isActive",
              value: String(!this.selectedFaction.isActive),
            },
          ],
          { id: this.selectedFaction.id }
        )
        .subscribe(
          (result) => {
            this.selectedFaction.isActive = !this.selectedFaction.isActive;
          },
          (error) => {
            this.addAlert({
              type: "danger",
              message:
                'Field: "' +
                this.selectedFaction.name +
                '" could not be updated!',
            });
          }
        );
    else
      this.addAlert({
        type: "danger",
        message:
          '"' +
          this.selectedFaction.name +
          '" cannot be activated! Faction Image is required!',
      });
  }

  view(value: any) {
    this.router.navigate(["admin/factions/" + value]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
