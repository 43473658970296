import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Action } from "src/app/models/action.model";
import { ActionOption } from "src/app/models/actionoption.model";
import { Alert } from "src/app/models/alert.model";
import { GameMode } from "src/app/models/gamemode.model";
import { Patch } from "src/app/models/patch.model";
import { Phase } from "src/app/models/phase.model";
import { ActionService } from "src/app/services/action.service";
import { ActionOptionService } from "src/app/services/actionoption.service";

@Component({
  selector: "app-action",
  templateUrl: "./action.component.html",
  preserveWhitespaces: true,
})
export class ActionComponent {
  actionOptions = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  modalTitle: string;
  modalBody: string;
  modalId: string;

  selectedActionOption: ActionOption;

  gameMode: GameMode;
  gameModeId: string = "";
  gameModeName: string = "Game Mode";

  phase: Phase;
  phaseId: string = "";
  phaseName: string = "Phase";

  action: Action;
  actionName: string = "Action";

  prevActionOption: ActionOption;

  alerts: Alert[] = [];

  filePath: string;

  hasLoadedAction: boolean = false;
  hasLoadedActionOptions: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private actionService: ActionService,
    private actionOptionService: ActionOptionService,
    private modalService: NgbModal
  ) {
    this.getAction();
  }

  getAction() {
    this.actionService
      .getFull<Action>({
        id: this.activatedRoute.snapshot.paramMap.get("actionId"),
      })
      .then(
        (result) => {
          this.action = result;
          this.phase = this.action.phase;
          this.phaseId = this.phase.id;
          this.phaseName = this.phase.name;
          this.gameMode = this.phase.gameMode;
          this.gameModeId = this.gameMode.id;
          this.gameModeName = this.gameMode.name;
          this.hasLoadedAction = true;
          this.actionOptions = this.action.actionOptions;
          this.linkActionOptions();
          this.hasLoadedActionOptions = true;
          this.setName();
        },
        (error) => {
          this.hasLoadedAction = false;
          this.addAlert({
            type: "danger",
            message: "Action could not be retrieved!",
          });
        }
      );
  }

  refresh() {
    this.resetActionOption();
    this.actionOptions = [];
    this.actionService.getFull<Action>({ id: this.action.id }).then(
      (result) => {
        this.actionOptions = result.actionOptions;
        this.linkActionOptions();
        this.hasLoadedActionOptions = true;
      },
      (error) => {
        this.hasLoadedActionOptions = false;
        this.addAlert({
          type: "danger",
          message: "Action Options could not be retrieved!",
        });
      }
    );
  }

  linkActionOptions() {
    for (let i = 0; i < this.actionOptions.length; i++) {
      this.actionOptions[i].nextActionOption =
        i < this.actionOptions.length ? this.actionOptions[i + 1] : null;
      this.actionOptions[i].previousActionOption =
        i > 0 ? this.actionOptions[i - 1] : null;
    }
  }

  setActionOption(id: string) {
    this.resetActionOption();
    this.selectedActionOption = this.actionOptions.find((actionOption) => {
      return actionOption.id === id;
    });
  }

  resetActionOption() {
    this.selectedActionOption = {
      id: "",
      name: null,
      description: null,
      priority: 0,
      created: "",
      modified: "",
      isActive: false,
      previousActionOption: null,
      nextActionOption: null,
      action: null,
    };
  }

  setName() {
    this.actionName = this.action.name;
  }

  setActionOptionModal(id?: string, title?: string, body?: string) {
    this.modalId = id;
    this.modalTitle = title;
    this.modalBody = body;
  }

  patch(patch: Patch, field: string) {
    this.actionService.patch<Patch>([patch], { id: this.action.id }).subscribe(
      (result) => {
        this.addAlert({
          type: "success",
          message: 'Field: "' + field + '" was updated successfully!',
        });
        this.setName();
      },
      (error) => {
        this.addAlert({
          type: "danger",
          message: 'Field: "' + field + '" could not be updated!',
        });
      }
    );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.action.name },
      "Name"
    );
  }

  createForm(content: any) {
    this.resetActionOption();
    this.setActionOptionModal("", "Create a new Action Option", "");
    this.modalService.open(content, { centered: true });
  }

  create() {
    this.selectedActionOption.description = this.selectedActionOption.name;
    this.prevActionOption = this.actionOptions
      .reverse()
      .filter((x) => x.nextActionOption == null)[0];
    this.actionOptionService
      .post<ActionOption>(this.selectedActionOption, {
        actionId: this.action.id,
        id: this.prevActionOption ? this.prevActionOption.id : "New",
      })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: "Action Option was created successfully!",
          });
          this.refresh();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Action Option could not be created!",
          });
        }
      );
  }

  toggleActionOptionConfirm(content: any, value: string) {
    this.setActionOption(value);
    this.setActionOptionModal(
      value,
      (this.selectedActionOption.isActive ? "Disable" : "Enable") +
        " " +
        this.selectedActionOption.name,
      "Are you sure you want to " +
        (this.selectedActionOption.isActive ? "disable" : "enable") +
        ' Action Option: "' +
        this.selectedActionOption.name +
        '"?'
    );
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result) {
          this.toggleActionOption();
        }
      })
      .catch((res) => {});
  }

  toggleActionOption() {
    this.actionOptionService
      .patch<Patch>(
        [
          {
            op: "replace",
            path: "/isActive",
            value: String(!this.selectedActionOption.isActive),
          },
        ],
        { id: this.selectedActionOption.id }
      )
      .subscribe(
        (result) => {
          this.selectedActionOption.isActive =
            !this.selectedActionOption.isActive;
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message:
              'Field: "' +
              this.selectedActionOption.name +
              '" could not be updated!',
          });
        }
      );
  }

  prev(actionOption: ActionOption) {
    this.update(
      {
        id: actionOption.id,
        name: actionOption.name,
        description: actionOption.description,
        priority: actionOption.priority,
        isActive: actionOption.isActive,
      },
      this.action.id,
      actionOption.previousActionOption.id
    );
  }

  next(actionOption: ActionOption) {
    this.update(
      {
        id: actionOption.id,
        name: actionOption.name,
        description: actionOption.description,
        priority: actionOption.priority,
        isActive: actionOption.isActive,
      },
      this.action.id,
      actionOption.nextActionOption.id
    );
  }

  update(actionOption: any, gameModeId: string, targetId: string) {
    this.actionOptionService
      .putPriority<ActionOption>(actionOption, {
        gameModeId: this.gameMode.id,
        phaseId: this.phase.id,
        actionId: gameModeId,
        targetId: targetId,
        id: actionOption.id,
      })
      .subscribe(
        (result) => {
          this.refresh();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: "Priority could not be updated! Error: " + error,
          });
        }
      );
  }

  view(value: any) {
    this.router.navigate([
      "admin/gamemodes/" +
        this.gameMode.id +
        "/phases/" +
        this.phase.id +
        "/actions/" +
        this.action.id +
        "/actionoptions/" +
        value,
    ]);
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
