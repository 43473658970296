import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Action } from "src/app/models/action.model";
import { ActionOption } from "src/app/models/actionoption.model";
import { Alert } from "src/app/models/alert.model";
import { GameMode } from "src/app/models/gamemode.model";
import { Patch } from "src/app/models/patch.model";
import { Phase } from "src/app/models/phase.model";
import { ActionOptionService } from "src/app/services/actionoption.service";

@Component({
  selector: "app-actionoption",
  templateUrl: "./actionoption.component.html",
  preserveWhitespaces: true,
})
export class ActionOptionComponent {
  gameMode: GameMode;
  gameModeId: string = "";
  gameModeName: string = "Game Mode";

  phase: Phase;
  phaseId: string = "";
  phaseName: string = "Phase";

  action: Action;
  actionId: string = "";
  actionName: string = "Action";

  actionOption: ActionOption;
  actionOptionName: string = "Action Option";
  actionOptionImagePath: string;

  alerts: Alert[] = [];

  hasLoadedActionOption: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private actionOptionService: ActionOptionService
  ) {
    this.getActionOption();
  }

  getActionOption() {
    this.actionOptionService
      .getFull<ActionOption>({
        id: this.activatedRoute.snapshot.paramMap.get("actionOptionId"),
      })
      .then(
        (result) => {
          this.actionOption = result;
          this.action = this.actionOption.action;
          this.actionId = this.action.id;
          this.actionName = this.action.name;
          this.phase = this.action.phase;
          this.phaseId = this.phase.id;
          this.phaseName = this.phase.name;
          this.gameMode = this.phase.gameMode;
          this.gameModeId = this.gameMode.id;
          this.gameModeName = this.gameMode.name;
          this.hasLoadedActionOption = true;
          this.setName();
        },
        (error) => {
          this.hasLoadedActionOption = false;
          this.addAlert({
            type: "danger",
            message: "Action Option could not be retrieved!",
          });
        }
      );
  }

  setName() {
    this.actionOptionName = this.actionOption.name;
  }

  patch(patch: Patch, field: string) {
    this.actionOptionService
      .patch<Patch>([patch], { id: this.actionOption.id })
      .subscribe(
        (result) => {
          this.addAlert({
            type: "success",
            message: 'Field: "' + field + '" was updated successfully!',
          });
          this.setName();
        },
        (error) => {
          this.addAlert({
            type: "danger",
            message: 'Field: "' + field + '" could not be updated!',
          });
        }
      );
  }

  editName() {
    this.patch(
      { op: "replace", path: "/name", value: this.actionOption.name },
      "Name"
    );
  }

  addAlert(alert: Alert) {
    this.alerts = [];
    this.alerts.push(alert);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
