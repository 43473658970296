import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/auth/core.module";
import { AuthInterceptor } from "./core/interceptors/auth-interceptor";
import { LayoutModule } from "./views/layout/layout.module";
import { AccountModule } from "./views/pages/account/account.module";
import { AdminModule } from "./views/pages/admin/admin.module";
import { AuthModule } from "./views/pages/auth/auth.module";
import { ErrorModule } from "./views/pages/error/error.module";
import { HeuristicsModule } from "./views/pages/heuristics/heuristics.module";
import { HomeModule } from "./views/pages/home/home.module";
import { SimulationModule } from "./views/pages/simulation/simulation.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    CoreModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    ErrorModule,
    AuthModule,
    HomeModule,
    AdminModule,
    SimulationModule,
    HeuristicsModule,
    AccountModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {}
